import types from './types';

export default {
	/**
	 * Sets the loading state for pre configuration
	 * @param {*} state
	 * @param {boolean} flag true if the offering has the creation enabled, false otherwise
	 */
	[types.SET_LOADING_PRECONFIG](state, flag) {
		state.isLoadingPreconfig = flag;
	},
	/**
	 * Sets the Pre Configuration of the offering
	 * @param {*} state
	 * @param {boolean} flag true if the offering has the creation enabled, false otherwise
	 */
	[types.SET_PRECONFIGURATION](state, preConfig) {
		state.preConfiguration = preConfig;
	},
	/**
	 * Sets the Pre Configuration list
	 * @param {*} state
	 * @param {boolean} preConfigs the array of options for pre configurations
	 */
	[types.SET_PRECONFIGURATION_LIST](state, preConfigs) {
		state.preConfigurationList = preConfigs;
	},
	[types.SET_CONFIGURATIONS_TO_COMPARE](state, configs) {
		state.configurationsToCompare = configs;
	},
	[types.SET_COMPARISON_SPEC_TYPE](state, specType) {
		state.comparisonSpecType = specType;
	},
};
