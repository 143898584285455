/**
 * Takes an array of objects and the key you want to base the sort on
 * and sorts the array based on the date desc
 * @param array array of objects
 * @param objKey key that the sort will be based on
 * @returns sorted array based on date
 */
export const sortDatesDesc = (array, objKey) => array.sort((a, b) => {
	const dateA = new Date(a[objKey]);
	const dateB = new Date(b[objKey]);
	return dateB - dateA;
});

/**
 * Takes an array of objects and the key you want to base the sort on
 * and sorts the array based on the date asc
 * @param array array of objects
 * @param objKey key that the sort will be based on
 * @returns sorted array based on date
 */
export const sortDatesAsc = (array, objKey) => array.sort((a, b) => {
	const dateA = new Date(a[objKey]);
	const dateB = new Date(b[objKey]);
	return dateA - dateB;
});
