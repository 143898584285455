import offeringsService from '@/services/offerings';
import functionsService from '@/services/functions';
import mutation from '../mutations/types';

/**
 * Fetch an offering data w/ prices
 * @param {object} context vuex context object
 * @param {object} parameters
 * @param {string} parameters.offeringId
 * @param {string} parameters.countryCode
 */
export async function getOffering({ state, commit }, { offeringId, countryCode }) {
	try {
		// Might not be the best place to check it but
		// avoid logic over the app to flush offering info
		if (state.offering?.offeringId === offeringId) {
			return;
		}

		commit(mutation.FLUSH_OFFERING_INFO);

		const result = await offeringsService.searchById(offeringId, countryCode);

		if (result?.productType === 'Software') {
			result.functions = await functionsService.searchByOfferingId(offeringId);
		}

		commit(mutation.SET_OFFERING_INFO, result);

		return result;
	} catch (error) {
		if (error.status) {
			throw new Error(`${error.status} - ${error.message}`);
		}
	}
}
