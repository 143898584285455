const FileCountry = (label, countryName) => {
	const container = document.createElement('div');
	const labelDOM = document.createElement('div');
	const countryNameDOM = document.createElement('p');

	container.classList.add('bx--form-requirement');
	labelDOM.classList.add('bx--form-requirement__title');
	countryNameDOM.classList.add('bx--form-requirement__supplement');

	labelDOM.textContent = label;
	countryNameDOM.textContent = countryName;

	container.appendChild(labelDOM);
	container.appendChild(countryNameDOM);

	return container;
};

export default FileCountry;
