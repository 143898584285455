import { actionMonitoring } from '@/utils';

let cookieConsent = '';

export function monitoringConsent() {
	const	cookies = document.cookie.split(';').reduce((acc, entry) => {
		const [name, value] = entry.split(/\s?(.*?)=(.*)/).splice(1, 2);
		acc.set(name, value);
		return acc;
	}, new Map());

	if (cookieConsent !== cookies.get('notice_gdpr_prefs')) {
		if (cookies.get('notice_gdpr_prefs')?.includes('1')) {
			actionMonitoring.enableMonitoring();
		} else {
			actionMonitoring.disableMonitoring();
		}
		cookieConsent = cookies.get('notice_gdpr_prefs');
	}
}
