/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */

import {
	ValidationProvider,
	configure,
	extend,
	setInteractionMode,
} from 'vee-validate';
import i18n from '@/i18n';
import { regexForTheInputOnSearch } from '@/utils';

const RegisterVeeValidatePlugin = (VueInstance) => {
	VueInstance.component('ValidationProvider', ValidationProvider);

	// Change validation behavior
	setInteractionMode('eager');

	// Configure default messages for fields validations
	configure({
		defaultMessage: (field, values) => {
			values._field_ = i18n.t(`fields.${field}`);
			return i18n.t(`validation.${values._rule_}`, values);
		},
	});

	// Install rules
	extend('invalidInputForSearch', (value, options) => regexForTheInputOnSearch[options[0]]
		.some(regexp => regexp.exec(value)));
};

export default RegisterVeeValidatePlugin;
