import types from './types';

export default {
	/**
	 * Sets if the CFR Menu should be open
	 * @param {*} state
	 * @param {boolean} flag true if the offering has the creation enabled, false otherwise
	 */
	[types.SET_IS_CFR_CREATION_EXPANDED](state, flag) {
		state.isCfrCreationExpanded = flag;
	},
	/**
	 * Populate state with offering and it's list, maintenance and upgrade prices field
	 * @param {object} state Offering store state
	 * @param {object} offering
	 */
	[types.SET_OFFERING_INFO](state, offering) {
		state.functions = offering.functions;
		state.offering = {
			...offering,
			prices: undefined,
			conversionPrices: undefined,
			functions: undefined,
		};
	},
	/**
	 * Reset all offering & prices data
	 * @param {object} state Offering store state
	 */
	[types.FLUSH_OFFERING_INFO](state) {
		state.functions = null;
		state.offering = null;
	},
};
