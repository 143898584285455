import { parseCurrencyValue } from '@/utils';

export function formatToMoney(value, currency) {
	let parsedValue = parseCurrencyValue(value);

	if (parsedValue !== '-' && currency) {
		parsedValue += ` ${currency}`;
	}

	return parsedValue;
}
