export const routeNames = Object.freeze({
	ComparisonTool: 'ComparisonTool',
	ConfigurationPicker: 'ConfigurationPicker',
	Feature: 'Feature',
	FunctionPrice: 'FunctionPrice',
	Home: 'Home',
	ManageAccess: 'ManageAccess',
	NotFound: 'NotFound',
	Offering: 'Offering',
	Support: 'Support',
});
