import { getReportStatus as reportLookup } from '@/services/report';
import mutation from '../mutations/types';

/**
 * Searches offerings by the inputted offeringId
 * @param context Store instance's methods and properties
 * @param offeringId Inputted offering's id
 */
export async function getReportStatus({ commit, state }, options, onLoop) {
	try {
		// hackish way to avoid looping when user decides
		// not to wait for it.
		if (state.status === 'not_started' && onLoop) {
			return;
		}

		const { tries, maxTries } = state;
		const { status, id } = await reportLookup(options);

		if (state.status !== status) {
			commit(mutation.SET_STATUS, status);
			commit(mutation.SET_REPORT_ID, id);
		}

		if (status === 'processing' && tries < maxTries) {
			commit(mutation.INCREMENT_TRIES);
			setTimeout(() => getReportStatus({ commit, state }, options, true), 2000);
		} else if (tries >= maxTries) {
			commit(mutation.SET_ERROR_STATUS, 500);
			commit(mutation.SET_STATUS, 'error');
		}
	} catch (error) {
		commit(mutation.SET_ERROR_STATUS, error.status);
		commit(mutation.SET_STATUS, 'error');
	}
}
