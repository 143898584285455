import types from './types';

export default {
	/**
	 * Set user name & email
	 * @param {object} state
	 * @param {object} user user name and email
	 * @param {string} user.name user name
	 * @param {string} user.email user name
	 * @param {string} user.country user country
	 */
	[types.SET_USER](state, user) {
		const {
			name,
			email,
			country,
			isIbmer,
			promotionalOfferings,
			hasAccessToPromotionalData,
		} = user;

		state.name = name || '';
		state.email = email || '';
		state.country = country;
		state.isIbmer = isIbmer;
		state.promotionalOfferings = promotionalOfferings;
		state.hasAccessToPromotionalData = hasAccessToPromotionalData;
	},
	/**
	 * Set user loading
	 * @param {object} state
	 * @param {boolean} flag user is loading
	 */
	[types.SET_USER_LOADING](state, flag) {
		state.isLoading = flag;
	},
	/**
	 * Set hasError flag and errorCode fields
	 *
	 * **errorCode is only mutable when hasError is truthy and
	 * is reset when hasError is false**
	 * @param {object} state
	 * @param {object} payload
	 * @param {boolean} payload.hasError
	 * @param {number} payload.errorCode
	 */
	[types.SET_USER_ERROR](state, { hasError, errorCode }) {
		state.hasError = hasError ?? state.hasError;

		if (state.hasError && errorCode >= 400) {
			state.errorCode = errorCode;
		} else if (!state.hasError) {
			state.errorCode = null;
		}
	},
};
