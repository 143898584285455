import RegisterNotificationPlugin from './notification';
import RegisterVeeValidatePlugin from './vee-validate';
import RegisterVueVirtualScroller from './vue-virtual-scroller';

const RegisterPlugins = (VueInstance) => {
	RegisterNotificationPlugin(VueInstance);
	RegisterVeeValidatePlugin(VueInstance);
	RegisterVueVirtualScroller(VueInstance);
};

export default RegisterPlugins;
