import axios from '@axios/axios';

/**
 * Searches an offering by its ID and country availability
 * @param offeringId Offering's id
 * @param licenseFunctionId License Function's id
 * @param countryISOCode Country's ISO Code
 * @returns Promise of functions' price search result
 */
export default async (offeringId, licenseFunctionId, countryISOCode) => {
	let result;
	try {
		const url = '/licenseFunction/prices';
		const options = {
			params: {
				offeringId,
				licenseFunctionId,
				countryISOCode,
			},
		};

		result = await axios.get(url, options);
	} catch (error) {
		if (error.status === 404) {
			return null;
		}
		throw new Error(`${error.status} - ${error.statusText}`);
	}
	return result;
};
