import getPreConfigFeatures from './getPreConfigFeatures';
import getPreConfigsList from './getPreConfigList';
import addConfigurationsToCompare from './addConfigurationsToCompare';
import removeConfigurationToCompare from './removeConfigurationToCompare';

export default {
	getPreConfigFeatures,
	getPreConfigsList,
	addConfigurationsToCompare,
	removeConfigurationToCompare,
};
