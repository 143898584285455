import featureService from '@/services/features';
import featureMutationsType from '@/store/modules/features/mutations/types';
import { formatDate } from '@/utils';
import mutationsTypes from '../../mutations/types';

function formatFeatures(features, offeringId, country) {
	return features.map(feature => ([
		feature.featureCode,
		feature.description,
		feature.featureCategory,
		feature.maximumAllowed,
		feature.zeroPriced,
		feature.pricedIndicator,
		feature.listPriceValue || '-',
		feature.listPriceCurrency || '-',
		formatDate(feature.availability.availabilityDate) || '-',
		`/feature/${feature.featureCode}?offeringId=${offeringId}&country=${country}`,
		feature.quantity,
	]));
}
/**
 * Searches features by the pre configuration parameter
 * @param context Store instance's methods and properties
 * @param preConfig Pre configuration set of features
 */
// eslint-disable-next-line func-names
export default async function ({ commit, rootState }, offeringId) {
	try {
		// Prepares data
		const countryISOCode = rootState.Countries.selected.isoCode;
		const preConfigId = rootState.PreAnnouncement.preConfiguration.id;

		commit(mutationsTypes.SET_LOADING_PRECONFIG, true);

		// Executes the search
		const result = await featureService
			.getPreConfiguredFeatures(preConfigId, offeringId, countryISOCode);
		const features = formatFeatures(result, offeringId, countryISOCode);

		// Commit the result
		commit(`Features/${featureMutationsType.SET_SELECTED_FEATURES}`, features, { root: true });
		commit(`Features/${featureMutationsType.PARSE_FEATURE_TO_CFR_FORMAT}`, features, { root: true });
	} catch (error) {
		commit(`Features/${featureMutationsType.SET_SELECTED_FEATURES}`, [], { root: true });
	} finally {
		commit(mutationsTypes.SET_LOADING_PRECONFIG, false);
	}
}
