import axios from '@axios/axios';

/**
 * Associate configurations to CPQ Folder
 * @param {object} options
 * @param {string} options.groupId the group ID
 * @param {string} options.countryISOCode the current country
 * @param {string} options.folderId CPQHUB Folder to associate with
 * @param {string} options.configurations Configurations that gonna be associated
 * @returns Promise of associating this configurations
 */
export default async (options) => {
	const url = `/templates/configurationPicker/${options.groupId}/associate`;
	const payload = {
		countryISOCode: options.countryISOCode,
		folderId: options.folderId,
		configurations: options.configurations,
	};

	return axios.post(url, payload);
};
