import Vue from 'vue';

import router from './router/index.mjs';
import store from './store';
import filters from './filters';
import { monitoringConsent } from './utils/monitoringConsent';
import RegisterPlugins from './plugins';
import RegisterLayouts from './layouts';
import RegisterCarbonComponents from './carbon/components';

import './styles/main.scss';

import i18n from './i18n';

import App from './App.vue';

Vue.config.productionTip = false;

RegisterPlugins(Vue);
RegisterLayouts(Vue);
RegisterCarbonComponents(Vue);

setInterval(monitoringConsent, 500);

Vue.use(i18n);
Vue.use(filters);

new Vue({
	i18n,
	router,
	store,
	render: h => h(App),
}).$mount('#root');
