import loadLocales from './localesLoader';

const loadLocaleMessages = () => {
	const locales = loadLocales();
	const messages = {};

	locales.keys().forEach((key) => {
		const matched = key.match(/([A-Za-z0-9-_]+)\./i);
		if (matched && matched.length > 1) {
			const locale = matched[1];
			messages[locale] = locales(key);
		}
	});
	return messages;
};

export default loadLocaleMessages;
