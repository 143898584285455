import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export default {
	namespaced: true,

	state() {
		return {
			isCfrCreationEnabled: false,
			isCfrCreationExpanded: false,
			offering: null,
			prices: null,
			upgradePrices: null,
			functions: null,
		};
	},
	getters,
	mutations,
	actions,
};
