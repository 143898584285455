import preAnnouncedService from '@/services/preAnnounced';
import i18n from '@/i18n';
import mutationsTypes from '../../mutations/types';

/**
 * Searches features by the pre configuration parameter
 * @param context Store instance's methods and properties
 * @param preConfig Pre configuration set of features
 */
// eslint-disable-next-line func-names
export default async function ({ commit, rootState }, offeringId) {
	try {
		commit(mutationsTypes.SET_LOADING_PRECONFIG, true);
		const { promotionalOfferings } = rootState.User;

		// Executes the search
		const result = await preAnnouncedService.getOfferingConfigurations(offeringId);

		if (!promotionalOfferings.includes(offeringId)) {
			result.unshift({
				description: 'New Configuration',
				id: 'user-new-configuration-list',
				name: i18n.t('modal.newConfigurationButton.name'),
			});
		}

		// Commit the result
		commit(mutationsTypes.SET_PRECONFIGURATION_LIST, result);
	} catch (error) {
		commit(mutationsTypes.SET_PRECONFIGURATION_LIST, []);
	} finally {
		commit(mutationsTypes.SET_LOADING_PRECONFIG, false);
	}
}
