/**
 * Checks if offering is hardware (or maintenance)
 * @param {object} state
 * @param {object} state.offering
 * @returns {boolean}
 */
export function isHardware({ offering }) {
	return ['Hardware', 'Maintenance']
		.includes(offering?.productType);
}

/**
 * Checks if offering is software (or maintenance)
 * @param {object} state
 * @param {object} state.offering
 * @returns {boolean}
 */
export function isSoftware({ offering }) {
	return offering?.productType === 'Software';
}

/**
 * Validate if offering is eligible to have CFRs created
 * @param {object} state
 * @param {object} state.offering
 * @param {object} getters Offering store getters
 * @returns {boolean}
 */
export function isOfferingConfigurable({ offering }, getters) {
	return getters.isHardware
		&& ['Storage', 'Power']
			.includes(offering?.brand);
}

/**
 * Small helper to fetch offering name
 * @param {object} state
 * @param {object} state.offering
 * @returns {string}
 */
export function offeringName({ offering }) {
	return offering?.longName;
}

/**
 * Return offering warranty
 * @param {object} state
 * @param {object} state.offering
 * @returns {object}
 */
export function warrantyData({ offering }) {
	return offering?.warranties;
}

/**
 * Return offering availability & availability status
 * @param {object} state
 * @param {object} state.offering
 * @returns {object}
 */
export function availabilityData({ offering }) {
	if (offering) {
		return {
			...offering?.availability,
			availabilityStatus: offering?.availabilityStatus,
		};
	}

	return null;
}

/**
 * Return offering valueChain
 * @param {object} state
 * @param {object} state.offering
 * @returns {object}
 */
export function valueChain({ offering }) {
	return offering?.details?.valueChain;
}

/**
 * Offering 'informations' to be displayed on page header
 *
 * @TODO: update name to something more meaningful
 * @param {object} state
 * @param {object} state.offering
 * @returns {object}
 */
export function informations({ offering }) {
	if (offering) {
		const {
			availability,
			availabilityStatus,
			details,
			longName,
			offeringId,
			productType,
			unifiedTaxonomy,
			lastUpdated,
		} = offering || {};

		return {
			availability,
			availabilityStatus,
			details,
			longName,
			offeringId,
			productType,
			unifiedTaxonomy,
			lastUpdated,
		};
	}

	return null;
}

/**
 * Convert functions data to 2d array representing a table
 *
 * @TODO: test it
 * @param {object} state
 * @param {object} state.functions
 * @param {object} state.offering
 * @param {object} getters
 * @param {object} rootState
 * @returns {array}
 */
export function functionsToTable({ functions, offering }, getters, rootState) {
	if (!Array.isArray(functions)) {
		return { columns: [], data: [] };
	}

	const countryCode = rootState.Countries.selected?.isoCode;
	const columnsESW = [
		'Entitled Entity ID',
		'Entitled Entity Short Name',
		'Entitled Entity Availability',
		'Option',
	];
	const columnsCSW = [
		'License Function ID',
		'Short Name',
		'Availability',
		'Option',
	];
	const qs = `offeringId=${offering.offeringId}&country=${countryCode}`;

	return {
		columns: getters.valueChain === 'ESW' ? columnsESW : columnsCSW,
		data: functions.map(entry => [
			entry?.lfId || '-',
			entry?.licensedFunctionFullName || '-',
			entry?.availabilityStatus || '-',
			// Feature code may contain '/' (e.g. "N/A")
			`/function/prices/${encodeURIComponent(entry?.lfId)}?${qs}`,
		]),
	};
}
