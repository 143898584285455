/**
 * Given a min, max and number, returns number limited to
 * the [min, max] range (inclusive), when out of range,
 * returns the closest limit.
 *
 * @param {number} number current value
 * @param {number} min minimum value
 * @param {number} max maximum value
 * @returns the cap value
 */
export function cap(number, min, max) {
	return Math.max(min, Math.min(max, number));
}
