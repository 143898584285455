import featureService from '@/services/features';
import mutation from '../mutations/types';

export async function getOfferingFeatures({ commit, state }, parameters) {
	try {
		const {
			offeringId,
			countryCode,
			page,
			pageSize,
			search,
		} = parameters;

		// Might not be the best place to check it but (2)
		// avoid logic over the app to keep feature data cached.
		// If a forced reset must be done, flush mutation can be
		// called.
		if (
			state.pagination.size === pageSize
			&& state.pagination.page === page
			&& state.pagination.search === search
		) {
			return;
		}

		commit(mutation.SET_PAGINATION, {
			page,
			size: pageSize,
			search,
		});

		const result = await featureService.getOfferingFeatures(
			offeringId,
			countryCode,
			page,
			pageSize ?? state.pagination.size,
			search,
		);

		commit(mutation.SET_FEATURES, result.features);
		commit(mutation.SET_TOTAL_FEATURES, result.totalFeatures);

		return result;
	} catch (error) {
		if (error.statusCode) {
			throw new Error(`${error.statusCode} - ${error.message}`);
		}
	}
}
