import countries from '@/assets/countries.json';
import types from './types';

export default {
	/**
	 * Defines the Selected Country
	 * @param {object} state Vuex Countries Module state
	 * @param {object} countryIsoCode Selected country iso code
	 */
	[types.SET_SELECTED_COUNTRY](state, countryIsoCode) {
		const country = countries.find(c => c.isoCode === countryIsoCode);

		state.selected.name = country.name;
		state.selected.isoCode = country.isoCode;
	},
};
