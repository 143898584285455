import axios from '@axios/axios';

let abortController = null;

/**
 * Searches offerings & features based on their characteristics (search type)
 * @param {object} options
 * @param {string} options.countryISOCode Country's ISO Code
 * @param {string} options.searchInput search param inputed by the user
 * @param {string} options.searchType radio button value selected by the user
 * @param {string} options.pageSize quantity to fetch in the request
 * @param {string} options.page page which user
 * @returns Promise of offerings' search result
 */
export async function search(options) {
	if (abortController) {
		abortController.abort();
	}

	const url = '/offerings';
	const params = {
		countryISOCode: options.countryISOCode,
		searchType: options.searchType,
		query: options.searchInput,
		size: options.pageSize,
		page: options.page,
	};

	abortController = new AbortController();

	const result = await axios.get(url, {
		params,
		signal: abortController.signal,
	});

	abortController = null;

	return result;
}
