<template>
	<div>
		<CvButton
			v-if="cfrParsedFeatures.length"
			kind="ghost"
			class="reset-button"
			@click="resetAllfeatures()"
		>
			{{ $t('cfr.reset') }}
		</CvButton>

		<CvButton
			v-if="isPreAnnounced"
			kind="ghost"
			class="go-back--button"
			@click="goBackToConfigOptions()"
		>
			<IconChevronLeft />
			<span class="go-back--span">{{ preConfiguration.name }}</span>
		</CvButton>

		<CvAccordion v-if="cfrParsedFeatures.length">
			<CfrFeatureAccordionItem
				v-for="(feature, index) in cfrParsedFeatures"
				:key="feature.featureCode"
				:feature="feature"
				@onUpdateQuantity="modifyQuantity($event, index)"
				@onRemove="removeFeature"
			/>
		</CvAccordion>
		<p v-else>{{ $t('cfr.noFeaturesSelected') }}</p>
	</div>
</template>
<script>
	import {
		mapMutations,
		mapGetters,
		mapState,
		mapActions,
	} from 'vuex';
	import featuresTypes from '@/store/modules/features/mutations/types';
	import preAnnouncementTypes from '@/store/modules/preAnnouncement/mutations/types';
	import { IconTrashCan, IconChevronLeft } from '@/carbon/icons';
	import CfrFeatureAccordionItem from './CfrFeatureAccordionItem.vue';

	export default {
		name: 'CfrFeatureAccordion',
		components: {
			IconChevronLeft,
			CfrFeatureAccordionItem,
		},
		computed: {
			...mapGetters({
				isPreAnnouncedOffering: 'PreAnnouncement/isPreAnnouncedOffering',
			}),
			...mapState('Features', {
				cfrParsedFeatures: ({ cfrParsedFeatures }) => cfrParsedFeatures,
			}),
			...mapState('PreAnnouncement', {
				preConfiguration: ({ preConfiguration }) => preConfiguration,
			}),
			offeringId() {
				return this.$route.params?.id;
			},
			isPreAnnounced() {
				return this.isPreAnnouncedOffering(this.offeringId);
			},
		},
		created() {
			Object.assign(this, { IconTrashCan });
		},
		methods: {
			...mapMutations('Features', {
				removeSelectedFeature: featuresTypes.REMOVE_SELECTED_FEATURE,
				removeCfrParsedFeatures: featuresTypes.REMOVE_CFR_PARSED_FEATURE,
				clearSelectedFeatures: featuresTypes.CLEAR_SELECTED_FEATURES,
				updateFeatureQuantity: featuresTypes.UPDATE_FEATURE_QUANTITY,
				resetTotalPrice: featuresTypes.RESET_TOTAL_AMOUNT,
				setIsCustomized: featuresTypes.SET_IS_CUSTOMIZED,
			}),
			...mapMutations('PreAnnouncement', {
				setPreConfiguration: preAnnouncementTypes.SET_PRECONFIGURATION,
			}),
			...mapActions({
				getPreConfigFeatures: 'PreAnnouncement/getPreConfigFeatures',
				getPreConfigsList: 'PreAnnouncement/getPreConfigsList',
			}),
			removeFeature(featureCode) {
				this.setIsCustomized(true, 'removeFeature');
				this.removeSelectedFeature(featureCode);
				this.removeCfrParsedFeatures(featureCode);
			},
			goBackToConfigOptions() {
				this.setPreConfiguration('');
				this.clearSelectedFeatures();
				this.getPreConfigsList(this.offeringId);
			},
			modifyQuantity(quantity, featureIndex) {
				if (!Number.isNaN(quantity)) {
					this.updateFeatureQuantity({ featureIndex, quantity });
				}
			},
			resetAllfeatures() {
				this.clearSelectedFeatures();
				this.setIsCustomized(false);
				if (this.preConfiguration.id && this.preConfiguration.id !== 'user-new-configuration-list') {
					this.getPreConfigFeatures(this.offeringId);
				}
			},
		},
	};
</script>
<style lang="scss" scoped>
	::v-deep .bx--accordion__heading {
		-webkit-box-orient: horizontal;
		-webkit-box-direction: normal;
		-ms-flex-direction: normal;
		flex-direction: row;
		padding-left: $spacing-03;
		&::before {
			background-color: transparent;
			border: none;
		}

		svg.bx--accordion__arrow {
			margin-top: $spacing-03;
		}

		.bx--accordion__title {
			margin: 0;
		}

		.bx--accordion--start, .bx--accordion__arrow {
			margin: 8px 16px 0 0 !important;
		}
	}

	.accordion-head--margin-align {
		margin-top: $spacing-02;

		.currency {
			@include carbon--type-style('productive-heading-01');
		}
	}

	.features-and-price-information {
		width: 100%;
		margin-right: $spacing-05;
	}

	.list-content {
		height: 100%;
	}

	.go-back--button {
		@include carbon--type-style('productive-heading-01');
		font-size: .75rem;
		color: $gray-70;
		padding-left: 0;
		width: 100%;
		max-width: 100%; // overwritting some cascading max-width
		justify-content: flex-start;

		svg {
			margin-left: $spacing-03;
		}

		.go-back--span {
			text-align: left;
			padding-left: #{$spacing-03 + 0.2rem};
		}
	}
</style>
