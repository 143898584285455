import actions from './actions';
import mutations from './mutations';

export default {
	namespaced: true,

	state() {
		return {
			loading: false,
			error: false,
			searchResult: null,
			softwareError: false,
			searchType: 'offeringId',
			searchInput: '',
			searchLabelKey: '',
			searchValue: '',
			page: 1,
			pageSize: 5,
			pageSizes: [
				{ value: 5, selected: true },
				{ value: 10 },
				{ value: 25 },
				{ value: 50 },
				{ value: 100 },
			],
		};
	},
	actions,
	mutations,
};
