import { routeNames } from './routeNames.mjs';

const routes = [
	{
		path: '/',
		name: routeNames.Home,
		component: () => import(/* webpackChunkName: "Home" */ '../pages/HomePage/HomePage.vue'),
	},
	{
		path: '/manage-access',
		name: routeNames.ManageAccess,
		component: () => import(
			/* webpackChunkName: "ManageAccess" */
			'../pages/ManageAccessPage/ManageAccessPage.vue'
		),
	},
	{
		path: '/configurationPicker',
		name: routeNames.ConfigurationPicker,
		component: () => import(
			/* webpackChunkName: "ConfigurationPicker" */
			'../pages/ConfigurationPickerPage/ConfigurationPickerPage.vue'
		),
	},
	{
		path: '/support',
		name: routeNames.Support,
		meta: {
			breadcrumb: [
				{
					name: 'Home',
					link: '/',
					linkKeys: [],
					isLabelI18n: true,
					labelKey: 'general.homepage',
				},
				{
					name: 'Support',
					link: '',
					linkKeys: [],
					isLabelI18n: true,
					labelKey: 'general.support',
				},
			],
		},
		component: () => import(/* webpackChunkName: "Support" */ '../pages/SupportPage/SupportPage.vue'),
	},
	{
		path: '/offering/:id',
		name: routeNames.Offering,
		meta: {
			breadcrumb: [
				{
					name: 'Home',
					link: '/?search=searchedInput&value=searchedValue'
						+ '&labelKey=searchedLabelKey&type=searchedType&country=searchedCountryIsoCode',
					linkKeys: [
						{
							replaceableKey: 'searchedInput',
							value: 'searchInput',
						},
						{
							replaceableKey: 'searchedType',
							value: 'searchType',
						},
						{
							replaceableKey: 'searchedValue',
							value: 'searchValue',
						},
						{
							replaceableKey: 'searchedLabelKey',
							value: 'searchLabelKey',
						},
						{
							replaceableKey: 'searchedCountryIsoCode',
							value: 'selectedCountryIsoCode',
						},
					],
					isLabelI18n: true,
					labelKey: 'offeringDetails.breadcrumb.search',
				},
				{
					name: 'Offering',
					link: '',
					linkKeys: [],
					isLabelI18n: false,
					labelKey: 'longName',
				},
			],
		},
		component: () => import(/* webpackChunkName: "Offering" */ '../pages/OfferingPage/OfferingPage.vue'),
	},
	{
		path: '/feature/:featureCode',
		name: routeNames.Feature,
		meta: {
			breadcrumb: [
				{
					name: 'Home',
					link: '/?search=searchedInput&value=searchedValue'
						+ '&labelKey=searchedLabelKey&type=searchedType&country=searchedCountryIsoCode',
					linkKeys: [
						{
							replaceableKey: 'searchedInput',
							value: 'searchInput',
						},
						{
							replaceableKey: 'searchedType',
							value: 'searchType',
						},
						{
							replaceableKey: 'searchedValue',
							value: 'searchValue',
						},
						{
							replaceableKey: 'searchedLabelKey',
							value: 'searchLabelKey',
						},
						{
							replaceableKey: 'searchedCountryIsoCode',
							value: 'selectedCountryIsoCode',
						},
					],
					isLabelI18n: true,
					labelKey: 'offeringDetails.breadcrumb.search',
				},
				{
					name: 'Offering',
					link: '/offering/offeringId?country=countryISOCode&selectedTab=Features',
					linkKeys: [
						{
							replaceableKey: 'offeringId',
							value: 'offering.offeringId',
						},
						{
							replaceableKey: 'countryISOCode',
							value: 'availability.countryISOCode',
						},
					],
					isLabelI18n: false,
					labelKey: 'offering.longName',
				},
				{
					name: 'Feature',
					link: '',
					linkKeys: [],
					isLabelI18n: false,
					labelKey: 'description',
				},
			],
		},
		component: () => import(/* webpackChunkName: "Feature" */ '../pages/FeaturePage/FeaturePage.vue'),
	},
	{
		path: '/function/prices/:lfId',
		name: routeNames.FunctionPrice,
		meta: {
			mainContent: 'function-price',
			breadcrumb: [
				{
					name: 'Home',
					link: '/?search=searchedInput&value=searchedValue'
						+ '&labelKey=searchedLabelKey&type=searchedType&country=searchedCountryIsoCode',
					linkKeys: [
						{
							replaceableKey: 'searchedInput',
							value: 'searchInput',
						},
						{
							replaceableKey: 'searchedType',
							value: 'searchType',
						},
						{
							replaceableKey: 'searchedValue',
							value: 'searchValue',
						},
						{
							replaceableKey: 'searchedLabelKey',
							value: 'searchLabelKey',
						},
						{
							replaceableKey: 'searchedCountryIsoCode',
							value: 'selectedCountryIsoCode',
						},
					],
					isLabelI18n: true,
					labelKey: 'offeringDetails.breadcrumb.search',
				},
				{
					name: 'Offering',
					link: '/offering/offeringId?country=countryISOCode&selectedTab=Functions',
					linkKeys: [
						{
							replaceableKey: 'offeringId',
							value: 'offeringId',
						},
						{
							replaceableKey: 'countryISOCode',
							value: 'availability.countryISOCode',
						},
					],
					isLabelI18n: false,
					labelKey: 'longName',
				},
				{
					name: 'FunctionPrices',
					link: '',
					linkKeys: [],
					isLabelI18n: false,
					labelKey: 'entityFullName',
				},
			],
		},
		component: () => import(/* webpackChunkName: "FunctionPrice" */ '../pages/FunctionPricePage/FunctionPricePage.vue'),
	},
	{
		path: '/comparison',
		name: routeNames.ComparisonTool,
		component: () => import(/* webpackChunkName: "comparison" */ '../pages/ComparisonPage/ComparisonPage.vue'),
	},
	{
		path: '*',
		name: routeNames.NotFound,
		redirect: '/',
	},
];

export default routes;
