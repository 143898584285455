import Countries from './countries';
import Features from './features';
import Offering from './offering';
import Search from './search';
import User from './user';
import Report from './report';
import PreAnnouncement from './preAnnouncement';
import Prices from './prices';
import TemplateCart from './templateCart';

export default {
	Countries,
	Features,
	Offering,
	Search,
	User,
	Report,
	PreAnnouncement,
	Prices,
	TemplateCart,
};
