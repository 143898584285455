import axios from '@axios/axios';

export default {
	/**
	 * Fetch user information
	 * @returns {Promise}
	 */
	getInfo() {
		return axios.get('/user');
	},
};
