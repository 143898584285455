<template>
	<main class="main flex flex-auto flex-column pt-9">
		<CvInlineNotification
			v-if="stateInfo.showNotification"
			:title="stateInfo.notification.title"
			:sub-title="stateInfo.notification.subtitle"
			:kind="stateInfo.notification.kind"
			:low-contrast="true"
			:hide-close-button="true"
		/>

		<LoadingResults
			v-if="isLoading"
			:state="stateInfo.loadingState.state"
			:loadingText="$t(stateInfo.loadingState.text)"
		/>
		<div v-else-if="isFinished" class="mt-7 ml-7">
			<!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -- decorative image -->
			<img src="@/assets/images/success.svg" alt="" />

			<h1 class="mt-5 message-heading">{{ stateInfo.message.title }}</h1>
			<p class="mt-3">{{ stateInfo.message.content }}</p>
			<div class="message-buttons-container">
				<CvButton
					kind="primary"
					class="mt-5"
					@click="redirectToCPQ()"
				>
					{{ $t('configurationPicker.goToMyCpqFolder') }}
				</CvButton>
			</div>
		</div>
		<div v-else-if="isLoaded">
			<slot />
		</div>
		<div v-else class="mt-7 ml-7">
			<!-- eslint-disable-next-line @intlify/vue-i18n/no-raw-text -- decorative image -->
			<img src="@/assets/images/errorImage.svg" alt="" />

			<h1 class="mt-5 message-heading">{{ stateInfo.message.title }}</h1>
			<p class="mt-3">{{ stateInfo.message.content }}</p>
			<div class="message-buttons-container">
				<CvButton
					kind="primary"
					class="mt-5"
					@click="reloadPage"
				>
					{{ $t('configurationPicker.refresh') }}
				</CvButton>
				<a
					:href="cpqFolderLink"
					rel="noopener noreferrer"
					class="mt-5"
				>
					<span>{{ $t('configurationPicker.goToMyCpqFolder') }}</span>
				</a>
			</div>
		</div>
	</main>
</template>

<script>
	import VueTypes from 'vue-types';

	import { INLINE_LOADING_STATES } from '@/constants/carbon/loading';
	import { NOTIFICATION_KIND } from '@/constants/carbon/notification';

	import { cpqHubLink } from '@/constants';
	import LoadingResults from '@/components/LoadingResults/LoadingResults.vue';

	export const PAGE_STATES = Object.freeze({
		LOADING: 'loading',
		NOTHING_TO_SHOW: 'nothingToShow',
		LOADED: 'loaded',
		ERROR: 'error',
		ASSOCIATING: 'associating',
		ERROR_ASSOCIATING: 'errorAssociating',
		SUCCESSFULL_ASSOCIATING: 'successfullAssociating',
	});

	const statesInfo = {
		[PAGE_STATES.LOADING]: {
			showNotification: false,
			loadingState: {
				state: INLINE_LOADING_STATES.LOADING,
				text: 'configurationPicker.loadingStates.loading',
			},
			notification: null,
			message: null,
		},
		[PAGE_STATES.ERROR_ASSOCIATING]: {
			showNotification: true,
			loadingState: {
				state: INLINE_LOADING_STATES.ERROR,
				text: 'configurationPicker.loadingStates.loading',
			},
			notification: {
				kind: NOTIFICATION_KIND.ERROR,
				title: 'configurationPicker.loadingStates.errorAssociating.title',
			},
			message: {
				title: 'configurationPicker.loadingStates.errorAssociating.message.title',
				content: 'configurationPicker.loadingStates.errorAssociating.message.content',
			},
		},
		[PAGE_STATES.SUCCESSFULL_ASSOCIATING]: {
			showNotification: true,
			loadingState: null,
			notification: {
				kind: NOTIFICATION_KIND.SUCCESS,
				title: 'configurationPicker.notifications.successfullAssociating.title',
			},
			message: {
				title: 'configurationPicker.notifications.successfullAssociating.message.title',
				content: 'configurationPicker.notifications.successfullAssociating.message.content',
			},
		},
		[PAGE_STATES.ASSOCIATING]: {
			showNotification: false,
			loadingState: {
				state: INLINE_LOADING_STATES.LOADING,
				text: 'configurationPicker.loadingStates.associating',
			},
			notification: null,
			message: null,
		},
		[PAGE_STATES.NOTHING_TO_SHOW]: {
			showNotification: false,
			loadingState: null,
			notification: null,
			message: {
				title: 'configurationPicker.loadingStates.nothingToShow.title',
				content: 'configurationPicker.loadingStates.nothingToShow.message',
			},
		},
		[PAGE_STATES.LOADED]: {
			showNotification: true,
			loadingState: null,
			notification: {
				kind: NOTIFICATION_KIND.INFO,
				title: 'configurationPicker.notifications.reducedVersionInfo.title',
				subtitle: 'configurationPicker.notifications.reducedVersionInfo.subtitle',
			},
			message: null,
		},
		[PAGE_STATES.ERROR]: {
			showNotification: true,
			loadingState: {
				state: INLINE_LOADING_STATES.ERROR,
				text: 'configurationPicker.loadingStates.loading',
			},
			notification: {
				kind: INLINE_LOADING_STATES.ERROR,
				title: 'configurationPicker.notifications.error.title',
				subtitle: null,
			},
			message: {
				title: 'configurationPicker.loadingStates.error.title',
				content: 'configurationPicker.loadingStates.error.message',
			},
		},
	};

	export default {
		name: 'ConfigurationPickerPageLayout',
		components: {
			LoadingResults,
		},
		props: {
			state: VueTypes.oneOf(Object.values(PAGE_STATES)),
			offeringName: VueTypes.string,
			folderId: VueTypes.string,
		},
		computed: {
			isLoading() {
				return [PAGE_STATES.LOADING, PAGE_STATES.ASSOCIATING].includes(this.state);
			},
			isFinished() {
				return this.state === PAGE_STATES.SUCCESSFULL_ASSOCIATING;
			},
			isLoaded() {
				return [PAGE_STATES.LOADED, PAGE_STATES.SUCCESSFULL_ASSOCIATING].includes(this.state);
			},
			stateInfo() {
				const {
					showNotification,
					notification,
					message,
					loadingState,
				} = statesInfo[this.state];
				return {
					showNotification,
					loadingState,
					notification: this.buildNotification(notification),
					message: this.buildMessage(message),
				};
			},
			cpqFolderLink() {
				return `${cpqHubLink}folders/${this.folderId || ''}`;
			},
		},
		methods: {
			redirectToCPQ() {
				window.location.href = this.cpqFolderLink;
			},
			buildNotification(notification) {
				if (!notification) {
					return null;
				}

				return {
					kind: notification.kind,
					title: this.$t(notification.title, { offeringName: this.offeringName }),
					subtitle: notification.subtitle ? this.$t(notification.subtitle) : '',
				};
			},
			buildMessage(message) {
				if (!message) {
					return null;
				}

				return {
					title: this.$t(message.title),
					content: this.$t(message.content),
				};
			},
			reloadPage() {
				this.$router.go(0);
			},
		},
	};
</script>

<style lang="scss" scoped>
	.bx--inline-notification {
		margin-top: 0;
		margin-bottom: 0;
		max-width: 100%;
	}

	.message-heading {
		@include carbon--type-style('productive-heading-04');
	}

	.message-content {
		@include carbon--type-style('body-short-01');
	}

	.message-buttons-container {
		display: flex;
		flex-direction: column;
	}
</style>
