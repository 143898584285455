<template>
	<CvHeader :aria-label="$t('general.productName')">
		<a
			href="/"
			aria-current="page"
			class="router-link-exact-active router-link-active cv-header-name bx--header__name"
			:title="$t('general.goToHomepage')"
		>
			<span class="bx--header__name--prefix prefix-space">
				{{ $t('general.productNamePrefix') }}
			</span>
			{{ $t('general.productName') }}
		</a>

		<template v-if="!isConfigurationPicker" slot="header-global">
			<span v-if="!isHomePage" class="country-name">{{ $t('general.countrySelected') }} {{ countryName }}</span>
			<CvHeaderGlobalAction
				v-if="selectedCountryIsoCode"
				:aria-label="$t('header.cfrConversion')"
				:label="$t('header.cfrConversion')"
				@click="isCfrFileConversionExpanded = !isCfrFileConversionExpanded"
			>
				<img src="@/assets/icons/cfrFileConverter.svg" :alt="$t('header.cfrConversion')">
			</CvHeaderGlobalAction>
			<CvHeaderGlobalAction
				:aria-label="$t('header.support')"
				:label="$t('header.support')"
				@click="$router.push('/support')"
			>
				<IconHelp width="25px" height="25px" />
			</CvHeaderGlobalAction>
			<CvHeaderGlobalAction
				v-if="enableCam"
				:label="$t('header.profile.buttonLabel')"
				:aria-controls="profileMenuId"
			>
				<IconUserAvatar width="25px" height="25px" />
			</CvHeaderGlobalAction>
			<CvHeaderGlobalAction
				:aria-label="$t('header.usefulLinks')"
				:label="$t('header.usefulLinks')"
				aria-controls="useful-links-panel"
				tipPosition="bottom"
				tipAlignment="end"
			>
				<IconSwitcher width="25px" height="25px" />
			</CvHeaderGlobalAction>
		</template>

		<template v-else slot="header-global">
			<CvHeaderGlobalAction
				:aria-label="$t('header.cart')"
				:label="$t('header.cart')"
				tipPosition="bottom"
				tipAlignment="end"
				@click="toggleTemplateCart(true)"
			>
				<IconCart width="25px" height="25px" class="icon-cart" />
				<div v-if="cartCounter > 0" class="counter">
					{{ cartCounter }}
				</div>
			</CvHeaderGlobalAction>
		</template>

		<template v-if="!isConfigurationPicker" #right-panels>
			<CvHeaderPanel id="cfr-creation" class="cfr-menu" :expanded="isCfrCreationExpanded">
				<CfrCreation v-show="isCfrCreationExpanded" />
			</CvHeaderPanel>
			<CvHeaderPanel id="cfr-file-conversion" class="cfr-menu" :expanded="isCfrFileConversionExpanded">
				<CfrFileConversion v-show="isCfrFileConversionExpanded" />
			</CvHeaderPanel>
			<CvHeaderPanel
				v-if="enableCam"
				:id="profileMenuId"
				ref="profileMenu"
				:aria-label="$t('header.profile.menuLabel')"
				tabindex="-1"
				@modelEvent="onProfileMenuExpandChanged"
			>
				<CvSwitcher v-show="isProfileMenuExpanded">
					<CvSwitcherItemLink to="/manage-access">{{ $t('header.profile.manageAccess') }}</CvSwitcherItemLink>
				</CvSwitcher>
			</CvHeaderPanel>
			<CvHeaderPanel
				id="useful-links-panel"
				v-model="isUsefulLinksExpanded"
			>
				<UsefulLinksPanel v-show="isUsefulLinksExpanded" />
			</CvHeaderPanel>
		</template>
		<template v-else #right-panels>
			<CvHeaderPanel
				v-show="isTemplateCartExpanded"
				id="template-cart"
				class="template-cart"
				:expanded="isTemplateCartExpanded"
			>
				<TemplateCart />
			</CvHeaderPanel>
		</template>
	</CvHeader>
</template>

<script>
	import { mapState, mapGetters, mapMutations } from 'vuex';
	import { EventBus } from '@/utils';
	import { cpqHubLink, eConfigLink, enableCam } from '@/constants';

	import {
		IconCart,
		IconHelp,
		IconSwitcher,
		IconUserAvatar,
	} from '@/carbon/icons';

	import TemplateCart from '@/components/TemplateCart/TemplateCart.vue';
	import CfrCreation from '@/components/Cfr/CfrCreation.vue';
	import CfrFileConversion from '@/components/Cfr/CfrFileConversion.vue';
	import UsefulLinksPanel from '@/components/UsefulLinksPanel/UsefulLinksPanel.vue';
	import { routeNames } from '@/router/routeNames.mjs';
	import templateCartTypes from '@/store/modules/templateCart/mutations/types';

	export default {
		name: 'CatalogHeader',
		components: {
			CfrCreation,
			CfrFileConversion,
			UsefulLinksPanel,
			TemplateCart,
			IconHelp,
			IconCart,
			IconSwitcher,
			IconUserAvatar,
		},
		data() {
			return {
				isCfrFileConversionExpanded: false,
				isUsefulLinksExpanded: false,
				isProfileMenuExpanded: false,
				profileMenuId: 'profile-menu',
				cpqHubLink,
				eConfigLink,
				enableCam,
			};
		},
		computed: {
			...mapState('Countries', {
				selectedCountryIsoCode: ({ selected }) => selected.isoCode,
				countryName: ({ selected }) => selected.name,
			}),
			...mapState('Offering', {
				isCfrCreationExpanded: ({ isCfrCreationExpanded }) => isCfrCreationExpanded,
			}),
			...mapGetters({
				cartCounter: 'TemplateCart/cartCounter',
			}),
			...mapState('TemplateCart', {
				isTemplateCartExpanded: ({ isTemplateCartExpanded }) => isTemplateCartExpanded,
			}),
			isConfigurationPicker() {
				return this.$route.name === routeNames.ConfigurationPicker;
			},
			// Change to user values when we have it
			user() {
				return {};
			},
			isHomePage() {
				return this.$route.path === '/';
			},
		},
		created() {
			EventBus.$on(
				'update:expand-cfr-file-conversion',
				(flag) => { this.isCfrFileConversionExpanded = flag; },
			);
		},
		methods: {
			...mapMutations('TemplateCart', {
				toggleTemplateCart: templateCartTypes.SET_IS_TEMPLATE_CART_EXPANDED,
			}),
			onProfileMenuExpandChanged(newState) {
				this.isProfileMenuExpanded = newState;
				if (newState) {
					this.$refs.profileMenu.$el.focus();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.counter {
		width: 1rem;
		text-align: center;
		height: 1rem;
		top: 1px;
		right: 1px;
		position: absolute;
		border-radius: 100px;
		background-color: $support-01;
		font-weight: 600;
		font-size: 0.625rem;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	header {
		z-index: 10000;

		.prefix-space {
			padding-right: $spacing-02;
		}

		.cfr-menu {
			background-color: $white-0;
			border: none;
			box-shadow: 0px 3px 6px rgba($black-100, 0.29);

			&.bx--header-panel--expanded {
				width: 25rem;
			}
		}
		.template-cart {
			background-color: $white-0;
			border: none;
			box-shadow: 0px 3px 6px rgba($black-100, 0.29);

			&.bx--header-panel--expanded {
				width: 30.25rem;
			}
		}
		.country-name {
			color: $white-0;
			padding: 0.85rem;
			font-size: 0.897rem;
		}
	}
</style>
