import axios from '@axios/axios';

/**
 * Searches a feature by its ID, offering Id and country availability
 * @param featureCode Feature's code
 * @param offeringId Offering's id
 * @param countryISOCode Country's ISO Code
 * @returns Promise of offerings' search result
 */
export default (featureCode, offeringId, countryISOCode) => {
	// Feature code may contain '/' (e.g. "N/A")
	const url = `/features/${encodeURIComponent(featureCode)}`;

	const options = {
		params: {
			offeringId,
			countryISOCode,
		},
	};

	return axios.get(url, options);
};
