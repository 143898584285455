import axios from '@axios/axios';

/**
 * Searches an offering by its ID and country availability
 * @param offeringId Offering's id
 * @returns Promise of functions' search result
 */
export default async (offeringId) => {
	let result;
	try {
		const url = '/licenseFunction';

		const options = {
			params: {
				offeringId,
			},
		};

		result = await axios.get(url, options);
	} catch {
		result = null;
	}
	return result;
};
