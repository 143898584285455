import axios from '@axios/axios';

/**
 * Get country iso code from .cfr file
 * @param payload.fileContent File content
 * @returns Promise to get the country iso code
 */
export default (payload) => {
	const url = '/cfr/country';

	const options = {
		headers: {
			'Content-Type': 'text/plain',
		},
	};

	return axios.post(url, payload, options);
};
