import { reportMaxTries } from '@/constants';

/**
 * @typedef ReportState
 * @property {'not_started' | 'ready' | 'no_data' | 'processing' | 'error'} status
 * @property {number} errorStatus
 * @property {number} tries
 * @property {maxTries} maxTries
 * @property {string} reportId
 */

/**
 * Report state factory function
 * @returns {ReportState}
 */
export function getCleanState() {
	return {
		status: 'not_started',
		tries: 0,
		maxTries: Number.parseInt(reportMaxTries, 10) || 100,
		reportId: null,
		errorStatus: null,
	};
}
