import { downloadReport } from '@/services/report';

/**
 * Searches offerings by the inputted offeringId
 * @param context Store instance's methods and properties
 * @param offeringId Inputted offering's id
 */
export async function getReportFile({ state }) {
	const { reportId } = state;
	return downloadReport(reportId);
}
