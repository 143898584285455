import types from './types';

export default {
	/**
	 * Flush all price type object to it's default
	 * values but list prices pagination size
	 * @param {object} state prices state
	 */
	[types.FLUSH_STATE](state) {
		state.listPrices = {
			data: null,
			total: 0,
			loaded: false,
			pagination: {
				page: 1,
				size: state.listPrices.pagination.size,
				search: null,
			},
		};

		state.maintenancePrices = {
			data: null,
			total: 0,
			loaded: false,
		};

		state.shippingHandlingPrices = {
			data: null,
			total: 0,
			loaded: false,
		};

		state.upgradePrices = {
			data: null,
			total: 0,
			loaded: false,
		};
	},
	[types.SET_UPGRADE_PRICES_INFO](state, info) {
		state.upgradePrices = {
			data: info.prices,
			total: info.total,
			loaded: true,
		};
	},
	[types.SET_MAINTENANCE_PRICES_INFO](state, info) {
		state.maintenancePrices = {
			data: info.prices,
			total: info.total,
			loaded: true,
		};
	},
	[types.SET_SHIPPING_HANDLING_PRICES_INFO](state, info) {
		state.shippingHandlingPrices = {
			data: info.prices,
			total: info.total,
			loaded: true,
		};
	},
	[types.SET_LIST_PRICES_INFO](state, info) {
		state.listPrices = {
			data: info.prices,
			total: info.total,
			loaded: true,
			pagination: {
				page: info.page,
				size: info.size ?? state.listPrices.pagination.size,
				search: info.search,
			},
		};
	},
	[types.SET_OFFERING_LIST_PRICE](state, price) {
		state.offeringListPrice = {
			value: Number(price.priceValue ?? 0),
			currency: price.currency || '',
		};
	},
};
