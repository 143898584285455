import axios from '@axios/axios';

/**
 * Creates a CFR file
 * @param offeringId Offering's id
 * @param countryISOCode Offering's country iso code
 * @param features selected features
 * @param isPreConfigurationDirty flag indicating w/ user touched config
 * @returns Promise of offerings' search result
 */
export default (
	preAnnouncedConfigurationId,
	offeringId,
	countryISOCode,
	features,
	isPreConfigurationDirty,
) => {
	const url = '/cfr/submit';

	const payload = {
		preAnnouncedConfigurationId,
		offeringId,
		countryISOCode,
		features,
		isPreConfigurationDirty,
	};

	return axios.post(url, payload);
};
