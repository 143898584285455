export default {
	/**
	 * Validate if given offeringId is
	 * a pre announced one
	 * @param {object} state
	 * @returns {boolean}
	 */
	isPreAnnouncedOffering(state) {
		return offeringId => state
			.availablePreAnnouncedOfferings
			.includes(offeringId);
	},
};
