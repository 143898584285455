import * as actions from './actions';
import mutations from './mutations';
import { getCleanState } from './state';

export default {
	namespaced: true,

	state() {
		return getCleanState();
	},
	actions,
	mutations,
};
