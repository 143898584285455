import { getField, updateField } from 'vuex-map-fields';

import * as getters from './getters';
import mutations from './mutations';
import * as actions from './actions';

export default {
	namespaced: true,

	state() {
		return {
			selectedFeatures: [],
			cfrParsedFeatures: [],
			isCustomized: false,
			features: [],
			total: 0,
			pagination: {
				page: 1,
				size: 25,
				search: null,
			},
		};
	},
	getters: {
		...getters,
		getField,
	},
	mutations: {
		...mutations,
		updateField,
	},
	actions,
};
