import axios from '@axios/axios';

/**
 * Get features of a offering, based on it's id
 * @param offeringId Offering's id
 * @param countryISOCode Country's ISO Code
 * @param fromIndex Starting index to get the features from
 * @returns Promise of offerings' search result
 */
export default (offeringId, countryISOCode, page, pageSize, search) => {
	const url = '/features';

	const options = {
		params: {
			offeringId,
			countryISOCode,
			page: page - 1,
			pageSize,
			search,
		},
	};

	return axios.get(url, options);
};
