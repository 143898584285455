import { REQUEST_STATUS } from '@/constants';
/**
 * Request interceptor to alter requests
 * @param {Object} config Axios configuration
 */
const requestInterceptor = config => config;

/**
 * Facilitate the access to returned data
 * @param {Object} response returned response from request
 */
const responseSuccessInterceptor = response => response.data;

/**
 * Facilitate access to returned rejection
 * @param {Object} error returned rejection
 */
const responseErrorInterceptor = (error) => {
	const response = error.response || error;
	const { status, data } = response;

	if (status === REQUEST_STATUS.UNAUTHORIZED && data?.authenticationUrl) {
		const appHost = window.location.href;
		window.location = `${data.authenticationUrl}?redirect_uri=${encodeURIComponent(appHost)}`;
	}

	return Promise.reject(response);
};

export {
	requestInterceptor,
	responseSuccessInterceptor,
	responseErrorInterceptor,
};
