import countriesList from '@/assets/countries.json';
import mutations from './mutations';

export default {
	namespaced: true,

	state() {
		return {
			selected: {
				name: '',
				isoCode: '',
			},
			list: countriesList,
		};
	},
	mutations,
};
