import types from './types';

export default {
	/**
	 * Sets offerings loading state
	 * @param {*} state
	 * @param {boolean} isLoading true if the offerings' data is loading, false otherwise
	 */
	[types.SET_LOADING](state, isLoading) {
		state.loading = isLoading;
	},
	/**
	 * Sets offerings error state
	 * @param {*} state
	 * @param {boolean} hasError true if there's an error, false otherwise.
	 */
	[types.SET_ERROR](state, hasError) {
		state.error = hasError;
	},
	/**
	 * Sets offerings data state
	 * @param {*} state
	 * @param {array} searchResult offerings' searching results
	 */
	[types.SET_SEARCH_RESULT](state, searchResult) {
		state.searchResult = searchResult;
	},
	/**
	 * Sets page state
	 * @param {*} state
	 * @param {array} page
	 */
	[types.SET_PAGE](state, page) {
		state.page = page;
	},
	/**
	 * Sets page size state
	 * @param {*} state
	 * @param {array} pageSize
	 */
	[types.SET_PAGE_SIZE](state, pageSize) {
		state.pageSize = pageSize;
		state.pageSizes = state.pageSizes.map((size) => {
			// eslint-disable-next-line no-param-reassign
			size.selected = pageSize === size.value;
			return size;
		});
	},
	/**
	 * Sets search information values
	 * @param {*} state
	 * @param {object} searchInfo
	 * @param {string} searchInfo.searchValue
	 * @param {string} searchInfo.searchInput
	 * @param {string} searchInfo.searchLabelKey
	 */
	[types.SET_SEARCH_INFO](state, searchInfo) {
		const {
			searchValue,
			searchInput,
			searchLabelKey,
			searchType,
		} = searchInfo;

		state.searchValue = searchValue || state.searchValue;
		state.searchInput = searchInput || state.searchInput;
		state.searchLabelKey = searchLabelKey || state.searchLabelKey;
		state.searchType = searchType;
	},
};
