import { sort } from 'fast-sort';
import types from './types';

export default {
	/**
	 * Sets selectedFeatures data state
	 * @param {*} state
	 * @param {array} selectedFeatures selected features from batch actions
	 */
	[types.SET_SELECTED_FEATURES](state, selectedFeatures) {
		state.selectedFeatures = [...selectedFeatures];
	},
	/**
	 * Clear selected features
	 * @param {*} state
	 */
	[types.CLEAR_SELECTED_FEATURES](state) {
		state.selectedFeatures = [];
		state.cfrParsedFeatures = [];
	},
	/**
	 * Remove selected feature
	 * @param {*} state
	 * @param {string} featureCode selected feature to be removed
	 */
	[types.REMOVE_SELECTED_FEATURE](state, featureCode) {
		state.selectedFeatures = state.selectedFeatures.filter(
			rawFeature => rawFeature[0] !== featureCode,
		);
	},
	/**
	 * @param {*} state
	 * @param {number} featureIndex
	 * @param {number} quantity new quantity of given feature for CFR creation
	 */
	[types.UPDATE_FEATURE_QUANTITY](state, { featureIndex, quantity }) {
		if (state.cfrParsedFeatures[featureIndex].quantity !== quantity) {
			state.cfrParsedFeatures[featureIndex].quantity = quantity;
		}
	},
	/**
	 * @param {*} state
	 * @param {number} features raw features that came from the table
	 * @param {array} selectedFeatures selected features from batch actions
	 */
	[types.PARSE_FEATURE_TO_CFR_FORMAT](state, features) {
		const parsedFeatures = features.map((feature) => {
			const price = feature[6];
			const currency = feature[7];
			// For pre configuration offerings, quantity already exists as index 10.
			const quantity = feature[10] ? feature[10] : 1;

			return {
				featureCode: feature[0],
				price: price.replace('-', ''),
				currency: currency.replace('-', ''),
				quantity,
				featureCategory: feature[2] || '-',
				listPrice: `${price} ${currency}`.replace('-', ''),
				availability: feature[8],
				featureDescription: feature[1],
				maximumAllowed: parseInt(feature[3], 10) || 9999,
			};
		});

		state.cfrParsedFeatures = sort(
			[...state.cfrParsedFeatures, ...parsedFeatures],
		).asc(f => f.featureCode);
	},
	/**
	 * @param {*} state
	 * @param {number} featureIndex
	 * @param {number} quantity new quantity of given feature for CFR creation
	 */
	[types.REMOVE_CFR_PARSED_FEATURE](state, featureCode) {
		state.cfrParsedFeatures = state.cfrParsedFeatures.filter(
			feature => feature.featureCode !== featureCode,
		);
	},
	/**
	 * @param {*} state
	 *  @param {boolean} flag
	 */
	[types.SET_IS_CUSTOMIZED](state, flag) {
		state.isCustomized = flag;
	},
	/**
	 * @param {object} state
	 * @param {array} features
	 */
	[types.SET_FEATURES](state, features) {
		state.features = features;
	},
	/**
	 * @param {object} state
	 * @param {number} total
	 */
	[types.SET_TOTAL_FEATURES](state, total) {
		state.total = total;
	},
	/**
	 * @param {object} state
	 * @param {object} params
	 * @param {number} params.page
	 * @param {number} params.size
	 * @param {string} params.search
	 */
	[types.SET_PAGINATION](state, { page, size, search }) {
		state.pagination = {
			page,
			size: size ?? state.pagination.size,
			search,
		};
	},
	/**
	 * Reset table related data to it's initial state,
	 * but keeping the last selected 'page size'.
	 * @param {object} state
	 */
	[types.FLUSH_FEATURES](state) {
		state.features = [];
		state.total = 0;
		state.pagination = {
			page: 1,
			size: state.pagination.size,
			search: null,
		};
	},
};
