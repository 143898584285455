import axios from '@axios/axios';

/**
 * Exports offerings & features
 * @param {object} params
 * @param {string} params.countryISOCode
 * @param {string} params.mimeType
 * @param {string} params.query
 * @param {string | undefined} params.priceType
 * @param {string} params.searchType
 * @returns Promise of offerings' search result
 */
export default async (params) => {
	const url = '/offerings/export';

	return axios.get(url, { params, responseType: 'blob' });
};
