import { paginationPageSizes } from '@/constants';

/**
 * Helper to map page sizes to CvDataTable format with option
 * to set one as 'selected's
 * @param {number} defaultSize size to be set as 'selected'
 * @returns {array}
 */
export function createTablePageSizes(defaultSize = 25) {
	return paginationPageSizes
		.map(size => ({
			value: size,
			selected: size === defaultSize,
		}));
}
