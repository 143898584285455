import {
	parseCurrencyValue,
	parseYesOrNo,
	formatDate,
	createTablePageSizes,
} from '@/utils';

export function cfrPayloadFeatures(state) {
	return state.cfrParsedFeatures.map(feature => feature.featureCode);
}

export function cfrFeaturesWithQuantities(state) {
	return state.cfrParsedFeatures.map(feature => ({
		featureCode: feature.featureCode,
		quantity: feature.quantity,
	}));
}

/**
 * Convert feature list to 2d array representing a table
 * for hardware
 *
 * @TODO: not tested as table information will change soon
 * @param {object} state
 * @param {object} state.features
 * @returns {object}
 */
export function hardwareFeaturesToTable({ features }, _getters, rootState) {
	if (!Array.isArray(features)) {
		return {
			columns: [],
			data: [],
		};
	}

	const offeringId = rootState.Offering.offering?.offeringId;
	const countryCode = rootState.Countries.selected?.isoCode;
	const qs = `offeringId=${offeringId}&country=${countryCode}`;

	return {
		columns: [
			'Feature Code',
			'Feature Category',
			'Customer Setup',
			'Feature Description',
			'Zero Priced',
			'Priced Indicator',
			'List Prices',
			'Currency',
			'Availability',
			'Option',
		],
		data: features.map(entry => [
			entry?.featureCode || '-',
			entry?.featureCategory || '-',
			entry?.customerSetup,
			entry?.description || '-',
			parseYesOrNo(entry.zeroPriced === null ? false : entry.zeroPriced),
			parseYesOrNo(entry.pricedIndicator === null ? false : entry.pricedIndicator),
			parseCurrencyValue(entry?.listPriceValue),
			entry?.listPriceCurrency || '-',
			entry?.availability?.availabilityDate ? formatDate(entry?.availability?.availabilityDate) : '-',
			// Feature code may contain '/' (e.g. "N/A")
			`/feature/${encodeURIComponent(entry?.featureCode)}?${qs}`,
		]),
	};
}

/**
 * Convert feature list to 2d array representing a table
 * for software
 *
 * @TODO: not tested as table information will change soon
 * @param {object} state
 * @param {object} state.features
 * @returns {object}
 */
export function softwareFeaturesToTable({ features }, _getters, rootState) {
	if (!Array.isArray(features)) {
		return {
			columns: [],
			data: [],
		};
	}

	const offeringId = rootState.Offering.offering?.offeringId;
	const countryCode = rootState.Countries.selected?.isoCode;
	const qs = `offeringId=${offeringId}&country=${countryCode}`;

	return {
		columns: [
			'Feature Code',
			'Feature Type',
			'Feature Description',
			'Priced Indicator',
			'List Prices',
			'Currency',
			'Price Type',
			'Option',
		],
		data: features.map(entry => [
			entry?.featureCode || '-',
			entry?.featureType || '-',
			entry?.description || '-',
			parseYesOrNo(entry.pricedIndicator === null ? false : entry.pricedIndicator),
			parseCurrencyValue(entry?.listPriceValue),
			entry?.listPriceCurrency || '-',
			entry?.listPriceType || '-',
			// Feature code may contain '/' (e.g. "N/A")
			`/feature/${encodeURIComponent(entry?.featureCode)}?${qs}`,
		]),
	};
}

/**
 * Convert state's pagination object to 'CvDataTable'
 * pagination object
 *
 * @param {state} state
 * @param {state} state.pagination
 * @param {state} state.total
 * @returns {object}
 */
export function paginationToTable({ pagination, total }) {
	return {
		numberOfItems: total,
		page: pagination.page,
		pageSizes: createTablePageSizes(pagination.size),
	};
}
