<template>
	<div
		class="counter--container flex flex-row"
		role="group"
		@click.stop.prevent.self
	>
		<CvIconButton
			class="gray-outline"
			:aria-label="$t('counter.downButtonAriaLabel')"
			:icon="IconSubtract"
			@click="decrement"
		/>
		<!--
			An label doesn't make sense in our Counter input (atm?)
			Adding aria-label should solve the issue for the
			focusable input.
		-->
		<!-- eslint-disable-next-line vuejs-accessibility/form-control-has-label -->
		<input
			v-show="editMode"
			ref="numberInput"
			v-model="internalValue"
			:aria-label="$t('counter.numberInputAriaLabel')"
			type="number"
			:min="min"
			:max="max"
			@blur="onNumberInputBlur"
		/>
		<input
			v-show="!editMode"
			readonly
			:value="mask"
			@focus="toggleEditMode"
		/>
		<CvIconButton
			class="blue-outline"
			:aria-label="$t('counter.upButtonAriaLabel')"
			:icon="IconAdd"
			@click="increment"
		/>
	</div>
</template>

<script>
	import VueTypes from 'vue-types';
	import { IconSubtract, IconAdd } from '@/carbon/icons';

	const maxMinType = VueTypes
		.oneOfType([
			VueTypes.integer,
			VueTypes.undefined,
		])
		.def(undefined);

	export default {
		name: 'CounterElement',
		props: {
			ariaLabel: VueTypes.string,
			value: VueTypes.oneOfType([
				VueTypes.integer,
				VueTypes.string,
			]),
			max: maxMinType,
			min: maxMinType,
		},
		data() {
			return {
				internalValue: '',
				editMode: false,
			};
		},
		computed: {
			mask() {
				return `x${this.internalValue}`;
			},
		},
		watch: {
			value(newValue) {
				this.update(newValue);
			},
		},
		mounted() {
			this.internalValue = parseInt(this.value, 10)
				|| this.min || 0;
		},
		created() {
			Object.assign(this, { IconSubtract, IconAdd });
		},
		methods: {
			toggleEditMode() {
				this.editMode = !this.editMode;
				if (this.editMode) {
					this.$nextTick(() => {
						this.$refs.numberInput.focus();
					});
				}
			},
			onNumberInputBlur(e) {
				const { value } = e.target;
				this.toggleEditMode();
				this.update(value);
			},
			decrement() {
				this.update(this.internalValue - 1);
			},
			increment() {
				this.update(this.internalValue + 1);
			},
			update(newValue) {
				const value = parseInt(Number(newValue), 10);

				if (this.min !== undefined && value < this.min) {
					this.internalValue = this.min;
				} else if (this.max !== undefined && value > this.max) {
					this.internalValue = this.max;
				} else if (Number.isNaN(value)) {
					this.internalValue = this.min || 0;
				} else {
					this.internalValue = value;
				}

				if (newValue !== this.value) {
					this.$emit('input', this.internalValue);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	.counter--container {
		font-size: 0.75rem;

		.cv-button {
			min-height: $spacing-02;
			justify-content: center;
			background-color: transparent;
			width: 24px;
			height: 24px;
			margin: 0;
		}

		.bx--btn--icon-only {
			padding-right: $spacing-04;
			padding-left: $spacing-04;
		}

		.blue-outline {
			border-color:$blue-60;
			color:$blue-60;

			&:hover, &:focus {
				color:$blue-70;
				border-color:$blue-70;
			}
		}
		.gray-outline {
			border-color: $gray-60;
			color: $gray-80;

			&:hover, &:focus {
				border-color: $gray-80;
				box-shadow: none;
				outline-color: $gray-80;
			}
		}

		input {
			width: $spacing-09;
			height: $spacing-06;
			text-align: center;
			color: $gray-60;
			background-color: $gray-10;
			border: none;

			/* Chrome, Safari, Edge, Opera */
			input::-webkit-outer-spin-button,
			input::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			/* Firefox */
			&[type=number] {
				-moz-appearance: textfield;
			}
		}

		::v-deep .bx--assistive-text,
		::v-deep .bx--tooltip__trigger::before {
			display: none !important;
		}
	}
</style>
