const downloadFile = (data, filename, options = {}) => {
	const url = window.URL.createObjectURL(new Blob([data], options));
	const link = document.createElement('a');
	link.href = url;
	link.setAttribute('download', filename);
	document.body.appendChild(link);
	link.click();
	document.body.removeChild(link);
};

export default downloadFile;
