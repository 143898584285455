var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CvAccordionItem',[_c('template',{slot:"title"},[_c('span',[_vm._v(" "+_vm._s(_vm.feature.featureCode)+" ")]),_c('span',{staticClass:"item-total-price"},[_vm._v(" "+_vm._s(_vm._f("formatToMoney")(_vm.total,_vm.feature.currency))+" ")]),_c('CounterElement',{attrs:{"value":_vm.feature.quantity,"max":_vm.feature.maximumAllowed,"min":1,"aria-label":_vm.$t(
				'cfr.counterInputAriaLabel',
				{ code: _vm.feature.featureCode },
			)},on:{"input":_vm.onUpdateQuantity}}),_c('CvIconButton',{staticClass:"remove--button",attrs:{"kind":"ghost","size":"small","aria-label":_vm.$t(
				'cfr.removeFeatureItem',
				{ code: _vm.feature.featureCode },
			),"icon":_vm.IconTrashCan},on:{"click":function($event){$event.stopPropagation();return _vm.onRemove.apply(null, arguments)}}})],1),_c('template',{slot:"content"},_vm._l((_vm.printableFields),function(field){return _c('p',{key:field,staticClass:"accordion__content"},[_c('span',{staticClass:"accordion__content--key"},[_vm._v(_vm._s(_vm.$t(`cfr.${field}`)))]),_c('span',{staticClass:"accordion__content--value"},[_vm._v(_vm._s(_vm.feature[field]))])])}),0)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }