import mutationsTypes from '../../mutations/types';

export default (
	{
		commit,
		rootState: { PreAnnouncement: { configurationsToCompare } },
	},
	{ configId, specType },
) => {
	try {
		const configs = [...configurationsToCompare];
		if (configs.length === 3) {
			throw new Error('maximumLimitReached');
		}

		configs.push(configId);
		commit(mutationsTypes.SET_CONFIGURATIONS_TO_COMPARE, configs);
		commit(mutationsTypes.SET_COMPARISON_SPEC_TYPE, specType);
	} catch (error) {
		return error;
	}
};
