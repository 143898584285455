import userService from '@/services/user';

import countriesMutationsTypes from '@/store/modules/countries/mutations/types';
import mutationsTypes from '../mutations/types';

export default {
	/**
	 * Get user information
	 * @param context Store instance's methods and properties
	 */
	async getInfo({ commit, rootState }) {
		try {
			commit(mutationsTypes.SET_USER_LOADING, true);
			commit(mutationsTypes.SET_USER_ERROR, { hasError: false });

			const user = await userService.getInfo();

			commit(mutationsTypes.SET_USER, user);

			const hasSelectedCountry = rootState.Countries.selected.isoCode;

			if (!hasSelectedCountry) {
				const { country } = user;
				commit(`Countries/${countriesMutationsTypes.SET_SELECTED_COUNTRY}`, country, { root: true });
			}

			commit(mutationsTypes.SET_USER_LOADING, false);
		} catch (error) {
			const statePayload = {
				hasError: true,
				errorCode: null,
			};

			if (error?.status >= 400) {
				statePayload.errorCode = error.status;
			}

			commit(mutationsTypes.SET_USER_ERROR, statePayload);
		}
	},
};
