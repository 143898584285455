import mutationsTypes from '../mutations/types';

export function addToCart(
	{
		commit,
		rootState: { TemplateCart: { configurations } },
	},
	config,
) {
	try {
		const isConfigurationFound = configurations.find(stateConfig => stateConfig.id === config.id);
		if (isConfigurationFound) {
			commit(mutationsTypes.SET_IS_TEMPLATE_CART_EXPANDED, true);
			return;
		}

		const newConfig = {
			...config,
			quantity: 1,
		};

		commit(mutationsTypes.ADD_CONFIGURATIONS, newConfig);
		commit(mutationsTypes.SET_IS_TEMPLATE_CART_EXPANDED, true);
	} catch (error) {
		return error;
	}
}

export function removeFromCart(
	{ commit	},
	position,
) {
	try {
		commit(mutationsTypes.REMOVE_CONFIGURATION, position);
	} catch (error) {
		return error;
	}
}

export function changeStatus(
	{ commit },
	status,
) {
	try {
		commit(mutationsTypes.CHANGE_STATUS, status);
	} catch (error) {
		return error;
	}
}

export function clearCart({	commit }) {
	try {
		commit(mutationsTypes.SET_CONFIGURATIONS, []);
	} catch (error) {
		return error;
	}
}
