import axios from '@axios/axios';

/**
 * Convert CFR files
 * @param payload.conversionExtension Extension
 * @param payload.fileContent Content
 * @param payload.fileName Name
 * @param payload.localTime Time
 * @returns Promise of files conversion
 */
export default (payload) => {
	const url = '/cfr/convert';

	const options = {
		responseType: 'json',
	};

	return axios.put(url, payload, options);
};
