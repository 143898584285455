/* eslint-disable no-return-await */
export default {
	// Action list
	PRE_CONFIG_CREATED: 'preConfigCreated',
	PRE_CONFIG_CLICKED: 'preConfigClicked',
	CFR_CONVERSION: 'cfrConversion',
	CFR_CREATION: 'cfrCreation',
	SEARCH_QUERY: 'searchQuery',

	/**
	* Enable Dynatrace
	*/
	enableMonitoring: () => {
		if (window.dtrum) {
			window.dtrum.enable();
		}
	},

	/**
	* Disable Dynatrace
	*/
	disableMonitoring: () => {
		if (window.dtrum) {
			window.dtrum.disable();
		}
	},

	/**
	* Page Action on Dynatrace
	* @param {string} action done name
	*/
	logAction: (action) => {
		if (window.dtrum) {
			const actionId = window.dtrum.enterAction(action);
			return actionId;
		}
	},

	/**
	* Page Action on Dynatrace
	* @param {string} action done name
	*/
	logXhrAction: (action) => {
		if (window.dtrum) {
			const actionId = window.dtrum.enterXhrAction(action);
			window.dtrum.leaveXhrAction(actionId);
			return actionId;
		}
	},

	/**
	* Page Action on Dynatrace
	* @param {string} errorMessage that will be demonstrated in dynatrace
	*/
	reportError: (errorMessage) => {
		if (window.dtrum) {
			window.dtrum.reportError(errorMessage);
		}
	},

	/**
	* Page Action on Dynatrace
	* @param {string} actionId id from the action that has the property
	* @param {Object} actionProperties properties from action that should be catch by Dynatrace
	* @param {string} actionKey what apepars on Dynatrace. The action itself
	* @param {string} actionValue value of the key
	*/
	addActionProperties(actionId, actionProperties) {
		if (window.dtrum) {
			Object.entries(actionProperties).forEach(([actionKey, actionValue]) => {
				window.dtrum.addActionProperties(actionId, undefined, undefined, {
					[actionKey]: {
						value: actionValue,
						public: true,
					},
				});
			});
		}
	},

	/**
	* Page Action on Dynatrace
	* @param {string} actionId id from the action that needs to leave
	*/
	leaveAction(actionId) {
		if (window.dtrum) {
			window.dtrum.leaveAction(actionId);
		}
	},

	/**
	* Page Action on Dynatrace
	* @param {string} actionName action done
	* @param {Object} actionProperties properties from action that should be catch by Dynatrace
	*/
	catchActionAndProperty(actionName, properties) {
		const actionId = this.logAction(actionName);
		this.addActionProperties(actionId, properties);
		this.leaveAction(actionId);
	},
};
