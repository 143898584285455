<template>
	<footer class="footer-container" aria-labelledby="footer">
		<div id="footer" class="container-position">
			<div class="content-image">
				<img src="@/assets/images/ibm-logo.png" :alt="$t('noResults.imgIBM')">
			</div>
			<div class="content-text">
				<div
					class="disclaimer"
					role="contentinfo"
					aria-labelledby="disclaimer"
				>
					<p id="disclaimer"> {{ $t('footer.disclaimer.text') }}</p>
				</div>
				<div class="links">
					<ul>
						<li>
							<CvLink
								aria-labelledby="contactIBM"
								href="https://www.ibm.com/contact/us/en/?lnk=flg-cont-usen"
								target="blank"
							>
								<span id="contactIBM">
									{{ $t('footer.links.contactIBM') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }}</span>
								</span>
							</CvLink>
						</li>
						<li>
							<CvLink
								aria-labelledby="accessibility"
								href="https://www.ibm.com/able/?lnk=flg-acce-usen"
								target="blank"
							>
								<span id="accessibility">
									{{ $t('footer.links.accessibility') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }}</span>
								</span>
							</CvLink>
						</li>
						<li class="bx--legal-nav__list-item" aria-labelledby="cookiePreferences">
							<div id="cookiePreferences">
								<span v-if="isCookieConsentEnabled" id="teconsent">
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }} </span>
								</span>
								<span v-else class="cookiePreferences">
									{{ $t('footer.links.cookiePreferences') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }} </span>
								</span>
							</div>
						</li>
						<li>
							<CvLink
								aria-labelledby="support"
								href="https://systemscatalog.ibm.com/support"
								target="blank"
							>
								<span id="support">
									{{ $t('footer.links.helpAndSupport') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }} </span>
								</span>
							</CvLink>
						</li>
						<li>
							<CvLink
								aria-labelledby="termsOfUse"
								href="https://www.ibm.com/legal?lnk=flg-tous-usen"
								target="blank"
							>
								<span id="termsOfUse">
									{{ $t('footer.links.termsOfUse') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }} </span>
								</span>
							</CvLink>
						</li>
						<li>
							<CvLink
								aria-labelledby="about"
								href="https://www.ibm.com/about?lnk=fab"
								target="blank"
							>
								<span id="about">
									{{ $t('footer.links.about') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }} </span>
								</span>
							</CvLink>
						</li>
						<li>
							<CvLink
								aria-labelledby="privacyPolicyInternal"
								href="https://www.ibm.com/us-en/privacy"
								target="blank"
							>
								<span id="privacyPolicyInternal">
									{{ $t('footer.links.privacyPolicyExternal') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }} </span>
								</span>
							</CvLink>
						</li>
						<li>
							<CvLink
								aria-labelledby="privacyPolicyExternal"
								href="https://w3.ibm.com/w3publisher/w3-privacy-notice/"
								target="blank"
							>
								<span id="privacyPolicyExternal">
									{{ $t('footer.links.privacyPolicyInternal') }}
									<span class="sr-only"> {{ $t('footer.accessibility.newWindowMessage') }} </span>
								</span>
							</CvLink>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</footer>
</template>
<script>
	import { enableCookieConsent } from '@/constants';

	export default {
		name: 'FooterDisclaimer',
		computed: {
			isCookieConsentEnabled() {
				if (enableCookieConsent) {
					const script = document.createElement('script');
					script.setAttribute('src', '//1.www.s81c.com/common/stats/ibm-common.js');
					script.setAttribute('async', 'async');
					document.body.appendChild(script);
				}
				return enableCookieConsent;
			},
		},
	};
</script>
<style lang="scss" scoped>
	.footer-container {
		width: 100%;
		background-color: $gray-90;

		.container-position {
			@include carbon--type-style('body-long-02');
			width: 100%;
			max-width: 1920px;
			padding: 34px;
			margin: auto;
			display: flex;
			flex-direction: row;
			color: $gray-40;
			font-size: 16px;
			justify-content: space-between;
			flex-wrap: wrap;
		}

		.content-image {
			width: 150px;
			height: 100px;
			padding-top: 20px;

			img {
				width: 117.04px;
				height: 47px;
			}
		}

		.content-text {
			width: 70%;
			display: flex;
			flex-direction: column;
		}

		.disclaimer {
			border-bottom: 1px solid $gray-50;

			p {
				margin-bottom: 30px ;
			}
		}

		.links > ul {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;

			li {
				width: 250px;
				padding-top: 20px;

				a.cv-link.bx--link {
						cursor: pointer;
						text-decoration: none;
						color: $gray-40;
						font-size: 16px;
						line-height: 20px;
				}
			}
		}
	}

	::v-deep .truste_cursor_pointer, .cookiePreferences  {
		cursor: pointer;
		text-decoration: none;
		height: auto;
		width: auto;
		font-size: 16px;
		color: $gray-40;
	}
</style>
