import axios from '@axios/axios';

/**
 * Get a configuration list of features ('main' offering features)
 * @param {string} preConfigId configuration's id
 * @param {string} offeringId Offering's id
 * @param {string} countryCode user selected country
 * @returns Promise of features search result
 */
export async function getPreConfiguredFeatures(preConfigId, offeringId, countryCode) {
	const response = await axios.get(
		`/cfr/${offeringId}/preconfig/${preConfigId}/features`,
		{ params: { countryCode } },
	);

	return response.features;
}
