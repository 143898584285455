<template>
	<nav>
		<div class="content pa-5 flex flex-column">
			<div class="flex justify-between items-center">
				<h2 id="cart-menu--label" class="menu--label">{{ $t('configurationCart.title') }}</h2>
				<CvIconButton
					kind="ghost"
					size="small"
					:label="$t('configurationCart.close')"
					:icon="IconClose"
					@click="closeCart"
				/>
			</div>
			<div class="description">
				{{ $t('configurationCart.description') }}
			</div>
			<div v-for="(config, position) in configurations" :key="config.id" class="config">
				<div class="type-model">
					<div class="label">{{ $t('configurationCart.typeModelLabel') }}</div>
					<div class="value">{{ config.offeringId }}</div>
				</div>
				<div class="config-content">
					<div class="content-wrapper">
						<div class="name">{{ config.name }}</div>
						<div class="counter">
							<CounterElement
								:value="config.quantity"
								:min="1"
								@input="(quantity) => changeQuantityValue(quantity, position)"
							/>
							<CvIconButton
								class="remove--button"
								kind="ghost"
								size="small"
								:label="$t('configurationCart.remove')"
								:icon="IconTrashCan"
								@click.stop="removeFromCart(position)"
							/>
						</div>
					</div>
					<div class="description">
						{{ config.description }}
					</div>
				</div>
			</div>
		</div>
		<div class="bx--modal-footer">
			<CvButton
				kind="secondary"
				@click="closeCart"
			>
				{{ $t('configurationCart.cancel') }}
			</CvButton>
			<CvButton
				class="flex justify-between pr-5"
				@click="submit"
			>
				<span>{{ $t('configurationCart.create') }}</span>
			</CvButton>
		</div>
	</nav>
</template>

<script>
	import { IconClose, IconTrashCan } from '@/carbon/icons';
	import CounterElement from '@/components/CounterElement/CounterElement.vue';
	import { mapMutations, mapState, mapActions } from 'vuex';
	import configurationPickerService from '@/services/configurationPicker';
	import templateCartTypes from '@/store/modules/templateCart/mutations/types';
	import { PAGE_STATES } from '@/layouts/ConfigurationPickerPageLayout.vue';

	export default {
		name: 'TemplateCart',
		components: {
			CounterElement,
		},
		data() {
			return {
				groupId: this.$route.query.type || '',
				folderId: this.$route.query.folderId || '',
				country: this.$route.query.country || '',
				hasError: false,
			};
		},
		computed: {
			...mapState('TemplateCart', {
				configurations: ({ configurations }) => configurations,
			}),
		},
		created() {
			Object.assign(this, { IconClose, IconTrashCan });
		},
		methods: {
			...mapMutations('TemplateCart', {
				toggleTemplateCart: templateCartTypes.SET_IS_TEMPLATE_CART_EXPANDED,
				setQuantity: templateCartTypes.SET_QUANTITY,
			}),
			...mapActions({
				removeFromCart: 'TemplateCart/removeFromCart',
				clearCart: 'TemplateCart/clearCart',
				changeStatus: 'TemplateCart/changeStatus',
			}),
			changeQuantityValue(quantity, position) {
				this.setQuantity({ quantity, position });
			},
			closeCart() {
				this.toggleTemplateCart(false);
			},
			async submit() {
				try {
					this.changeStatus(PAGE_STATES.ASSOCIATING);
					this.closeCart();

					const payload = {
						groupId: this.groupId,
						countryISOCode: this.country,
						folderId: this.folderId,
						configurations: this.configurations.map(config => ({
							templateId: config.id,
							quantity: config.quantity,
						})),
					};
					await configurationPickerService.associate(payload);
					this.changeStatus(PAGE_STATES.SUCCESSFULL_ASSOCIATING);
					this.clearCart();
				} catch (error) {
					this.changeStatus(PAGE_STATES.ERROR_ASSOCIATING);
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	nav {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
	}
	.amount {
		display: flex;
		justify-content: flex-end;
		.wrapper {
			display: flex;
			gap: $spacing-05;
			.value {
				font-size: 1rem;
				font-weight: 600;
			}
		}
	}
	.config {
		display: flex;
		flex-direction: column;
		width: 100%;
		gap: $spacing-05;
		.config-content {
			display: flex;
			flex-direction: column;
			padding: $spacing-05;
			justify-content: center;
			align-items: flex-start;
			gap: $spacing-05;
			border-top: 1px solid $border-strong;
			.content-wrapper {
				display: flex;
				justify-content: space-between;
				align-items: center;
				width: 100%;
			}
			.counter {
				display: flex;
				align-items: center;
				gap: $spacing-05;
			}
			.description {
				white-space: pre-line;
				word-wrap: break-word;
				font-size: 0.875rem;
				font-weight: 400;
				letter-spacing: 0.16px;
				color: $text-02;
			}
		}
		.config-details {
			display: flex;
			align-items: center;
			padding: $spacing-06;
		}
		.type-model {
			display: flex;
			align-items: center;
			justify-content: space-between;
			gap: $spacing-05;
			max-width: 200px;
			.label {
				font-size: 0.75rem;
				font-weight: 400;
				letter-spacing: 0.02rem;
			}
			.value {
				font-size: 0.875rem;
				font-weight: 600;
				letter-spacing: 0.01rem;
			}
		}
	}
	.content {
		gap: $spacing-05;
		color: #000;
		.description {
			font-weight: 400;
			letter-spacing: 0.02rem;
			font-size: 0.75rem;
			margin-bottom: $spacing-05;
		}
	}
	.menu--label {
		font-weight: 400;
		font-size: 1.25rem;
		font-style: normal;
		color: #000;
	}
	.close {
		margin: 0 0 0 (-$spacing-03);
		&:hover {
			background-color: #e5e5e5;
		}

		.bx--btn__icon > path {
			fill: $black-100;
		}
	}
</style>
