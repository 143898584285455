<template>
	<div class="preconfig-list">
		<div v-if="preConfigurationList.length">
			<p class="preconfig-options--span">{{ $t('cfr.preAnnounced.chooseAnOption') }}</p>
			<div v-for="preConfig in preConfigurationList" :key="preConfig.id" class="preconfig-item">
				<CvButton
					kind="ghost"
					class="preconfig-list--button"
					@click="loadPreConfigurationFeatures(preConfig)"
				>
					{{ preConfig.name }}
					<IconChevronRight />
				</CvButton>
			</div>
		</div>
		<p v-else class="preconfig-options--span preconfig-options--error">
			{{ $t('cfr.preAnnounced.noConfigAvailable') }}
		</p>
	</div>
</template>
<script>
	import { mapActions, mapMutations, mapState } from 'vuex';
	import featuresTypes from '@/store/modules/features/mutations/types';
	import preAnnouncedTypes from '@/store/modules/preAnnouncement/mutations/types';
	import { IconChevronRight } from '@/carbon/icons';
	import { selectedCountryIsoCodeMixin } from '@/mixins';
	import { actionMonitoring } from '@/utils';

	export default {
		name: 'PreConfiguredList',
		components: {
			IconChevronRight,
		},
		mixins: [selectedCountryIsoCodeMixin],
		computed: {
			...mapState('PreAnnouncement', {
				preConfigurationList: ({ preConfigurationList }) => preConfigurationList,
			}),
			offeringId() {
				return this.$route.params?.id;
			},
		},
		methods: {
			...mapMutations('PreAnnouncement', {
				setPreConfiguration: preAnnouncedTypes.SET_PRECONFIGURATION,
			}),
			...mapMutations('Features', {
				removeSelectedFeature: featuresTypes.REMOVE_SELECTED_FEATURE,
			}),
			...mapActions({
				getPreConfigFeatures: 'PreAnnouncement/getPreConfigFeatures',
				getPreConfigsList: 'PreAnnouncement/getPreConfigsList',
			}),
			loadPreConfigurationFeatures(preConfig) {
				this.setPreConfiguration(preConfig);

				if (preConfig.id !== 'user-new-configuration-list') {
					const	actionProperties = {
						configname: preConfig.name,
						configid: preConfig.id,
						offeringiddyna: this.offeringId,
						countrycode: this.selectedCountryIsoCode,
					};
					actionMonitoring.catchActionAndProperty(actionMonitoring.PRE_CONFIG_CLICKED, actionProperties);
					this.getPreConfigFeatures(this.offeringId);
				}
			},
		},

	};
</script>
<style lang="scss" scoped>
.preconfig-list {
	.preconfig-list--button {
		max-width: 100%; // overwritting some cascading max-width
		width: 100%;
		@include carbon--type-style('body-long-01');
		padding-top: 1rem;
		padding-bottom: 1rem;
		color: $black-100;
	}
	.preconfig-options--span {
		@include carbon--type-style('caption-01');
		color: $gray-70;
		margin-bottom: 1rem;

		&.preconfig-options--error {
			text-align: center;
		}
	}
	.preconfig-item {
		border-top: 1px solid $gray-20;
	}
	.preconfig-item:last-child {
		border-bottom: 1px solid $gray-20;
	}
}
</style>
