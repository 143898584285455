import CarbonVue from '@carbon/vue';

const RegisterCarbonComponents = (VueInstance) => {
	// Remember to import the correspondent component styles in "src/styles/carbon/components.scss"
	const componentsToBeImported = [
		'CvAccordion',
		'CvAccordionItem',
		'CvButton',
		'CvBreadcrumb',
		'CvBreadcrumbItem',
		'CvDataTable',
		'CvDataTableHeading',
		'CvDataTableAction',
		'CvDataTableCell',
		'CvDataTableRow',
		'CvDataTableSkeleton',
		'CvDatePicker',
		'CvDropdown',
		'CvDropdownItem',
		'CvFileUploader',
		'CvHeader',
		'CvHeaderGlobalAction',
		'CvHeaderName',
		'CvHeaderPanel',
		'CvIconButton',
		'CvInlineLoading',
		'CvInlineNotification',
		'CvLink',
		'CvModal',
		'CvRadioButton',
		'CvRadioGroup',
		'CvPagination',
		'CvSearch',
		'CvSkeletonText',
		'CvTextInput',
		'CvToastNotification',
		'CvComboBox',
		'CvTooltip',
		'CvInteractiveTooltip',
		'CvCheckbox',
		'CvSwitcher',
		'CvSwitcherItem',
		'CvSwitcherItemLink',
		'CvTabs',
		'CvTab',
		'CvToggle',
		'CvTile',
	];

	VueInstance.use(CarbonVue, componentsToBeImported);
};

export default RegisterCarbonComponents;
