<template>
	<CvModal
		ref="CvModal"
		size="small"
		:visible="isOpen"
		:close-aria-label="$t('modal.searchDownload.closeAriaLabel')"
		:primary-button-disabled="isPrimaryButtonDisabled"
		@modal-hidden="closeModal"
		@primary-click="selectedExport"
		@secondary-click="closeModal"
	>
		<template slot="title"> {{ label }} </template>

		<template slot="content">
			<p class="modal--subtitle">{{ $t('cfr.modalText') }}</p>
			<p class="modal--subtitle">{{ $t('cfr.modalQuestion') }}</p>
			<div class="mt-7">
				<CvRadioGroup vertical class="mb-6">
					<CvRadioButton
						v-for="(selection, index) in selections"
						:key="index"
						v-model="checked"
						:label="$t(`${selection.label}`)"
						:name="selection"
						:value="selection.value"
					/>
				</CvRadioGroup>
			</div>
		</template>
		<template slot="secondary-button">{{ $t('cfr.cancel') }}</template>
		<template slot="primary-button">
			<div class="flex items-center">
				{{ $t('cfr.create') }}
				<LoadingResults v-show="showLoading" state="loading" />
			</div>
		</template>
	</CvModal>
</template>

<script>
	import VueTypes from 'vue-types';

	import LoadingResults from '@/components/LoadingResults/LoadingResults.vue';

	export default {
		name: 'LoadPreConfigModal',
		components: {
			LoadingResults,
		},
		props: {
			isOpen: VueTypes.bool.isRequired,
			label: VueTypes.string.isRequired,
		},
		data() {
			return {
				selections: [
					{ label: 'cfr.downloadLabel', value: 'download' },
					{ label: 'cfr.eConfigLabel', value: 'eConfig' },
				],
				showLoading: false,
				checked: '',
			};
		},
		computed: {
			isPrimaryButtonDisabled() {
				return this.checked === '' || this.showLoading;
			},
		},
		methods: {
			closeModal() {
				this.$emit('close');
				this.showLoading = false;

				this.checked = '';
			},

			selectedExport() {
				this.showLoading = true;
				this.$emit('primary-click', this.checked);
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .bx--modal-container,
	::v-deep .bx--modal-container--md {

		.modal--subtitle {
			@include carbon--type-style('productive-heading-01');
			font-weight: 400;
		}
	}

	::v-deep .loading-message {
		margin-left: 10px;
	}

	::v-deep .bx--loading {
		animation-duration: 1100ms;
	}
</style>
