import types from './types';
import { getCleanState } from '../state';

export default {
	[types.SET_STATUS](state, status) {
		state.status = status;
	},
	[types.SET_STATUS](state, status) {
		state.status = status;
	},
	[types.SET_ERROR_STATUS](state, status) {
		state.errorStatus = status;
	},
	[types.INCREMENT_TRIES](state) {
		state.tries += 1;
	},
	[types.RESET_TRIES](state) {
		state.status = 0;
	},
	[types.SET_REPORT_ID](state, id) {
		state.reportId = id;
	},
	[types.FLUSH](state) {
		const newState = getCleanState();

		state.status = newState.status;
		state.tries = newState.tries;
		state.maxTries = newState.maxTries;
		state.file = newState.file;
		state.reportId = newState.reportId;
		state.errorStatus = newState.errorStatus;
	},
};
