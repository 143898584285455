import * as service from '@/services/prices';
import { parseSearchValue } from '@/utils/parseSearchValue';
import mutation from '../mutations/types';

export async function getUpgradePrices(
	{ commit },
	{ offeringId, countryCode },
) {
	try {
		const result = await service.getUpgradePrices(offeringId, countryCode);

		commit(mutation.SET_UPGRADE_PRICES_INFO, result);
	} catch (error) {
		if (error.statusCode) {
			throw new Error(`${error.statusCode} - ${error.message}`);
		}
	}
}

export async function getMaintenancePrices(
	{ commit },
	{ offeringId, countryCode },
) {
	try {
		const result = await service.getMaintenancePrices(
			offeringId,
			countryCode,
		);

		commit(mutation.SET_MAINTENANCE_PRICES_INFO, result);
	} catch (error) {
		if (error.statusCode) {
			throw new Error(`${error.statusCode} - ${error.message}`);
		}
	}
}

export async function getShippingHandlingPrices(
	{ commit },
	{ offeringId, countryCode },
) {
	try {
		const result = await service.getShippingHandlingPrices(
			offeringId,
			countryCode,
		);

		commit(mutation.SET_SHIPPING_HANDLING_PRICES_INFO, result);
	} catch (error) {
		if (error.statusCode) {
			throw new Error(`${error.statusCode} - ${error.message}`);
		}
	}
}

export async function getListPrices({ commit, state }, parameters) {
	try {
		const { pagination } = state.listPrices;
		const {
			offeringId,
			countryCode,
			page,
			pageSize,
			search,
		} = parameters;

		// Might not be the best place to check it but (3)
		// avoid logic over the app to keep feature data cached.
		// If a forced reset must be done, flush mutation can be
		// called.
		if (
			pagination.size === pageSize
			&& pagination.page === page
			&& pagination.search === search
		) {
			return;
		}

		const searchedValue = parseSearchValue(offeringId);

		const result = await service.getListPrices(
			searchedValue,
			countryCode,
			page,
			pageSize ?? pagination.size,
			search,
		);

		commit(
			mutation.SET_LIST_PRICES_INFO,
			{
				...result,
				page,
				search,
				size: pageSize,
			},
		);

		if (page === 1 && !search && !result.prices[0].featureCode) {
			commit(mutation.SET_OFFERING_LIST_PRICE, result.prices[0]);
		}
	} catch (error) {
		if (error.statusCode) {
			throw new Error(`${error.statusCode} - ${error.message}`);
		}
	}
}
