import axios from '@axios/axios';

/**
 * Creates a CFR file
 * @param offeringId Offering's id
 * @param countryISOCode Offering's country iso code
 * @param features selected features
 * @param isPreConfigurationDirty flag indicating w/user touched config and is optional in this file
 * @returns Promise of offerings' search result
 */
export default (preAnnouncedConfigurationId, offeringId, countryISOCode, features, isPreConfigurationDirty) => {
	const url = '/cfr/create';

	const options = {
		responseType: 'blob',
	};

	const payload = {
		preAnnouncedConfigurationId,
		offeringId,
		countryISOCode,
		features,
		isPreConfigurationDirty,
	};

	return axios.post(url, payload, options);
};
