export default {
	SET_LOADING: 'SET_LOADING',
	SET_ERROR: 'SET_ERROR',
	SET_SEARCH_RESULT: 'SET_SEARCH_RESULT',
	SET_SW_ERROR: 'SET_SW_ERROR',
	SET_SEARCH_TYPE: 'SET_SEARCH_TYPE',
	SET_SEARCH_INPUT: 'SET_SEARCH',
	SET_PAGE: 'SET_PAGE',
	SET_PAGE_SIZE: 'SET_PAGE_SIZE',
	SET_SEARCH_INFO: 'SET_SEARCH_INFO',
};
