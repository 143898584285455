<template>
	<nav
		:aria-label="$t('cfr.label')"
		class="flex flex-column cfr-menu"
		role="tablist"
	>
		<div class="content pa-5 flex flex-column">
			<div class="flex justify-between items-center">
				<h2 id="cfr-menu--label">{{ $t('cfr.title') }}</h2>
				<CvIconButton
					class="cfr-close"
					kind="ghost"
					size="small"
					label="Close"
					:icon="IconClose"
					@click="closeMenu"
				/>
			</div>
			<p class="features-length">
				{{ $t('cfr.length', { length: cfrParsedFeatures.length }) }}
				<span v-if="isCustomized" class="features-length">{{ $t('cfr.customized') }}</span>
			</p>
			<p class="features-information">{{ $t('cfr.information') }}</p>

			<div v-if="shouldShowFeatures" class="offering-base-information flex-row flex l-mb-2">
				<div class="flex-column flex">
					<span class="l-mb-1">{{ $t('cfr.baseOfferingPrice.typeModel') }}</span>
					<span>{{ $t('cfr.baseOfferingPrice.listPrice') }}</span>
				</div>
				<div class="flex-column flex l-ml-2">
					<span class="l-mb-1">
						<strong>{{ offeringId }}</strong>
					</span>
					<span>
						<strong>
							{{ offeringBasePrice.value | formatToMoney(offeringBasePrice.currency) }}
						</strong>
					</span>
				</div>
			</div>

			<PreConfiguredList
				v-if="shouldShowPreConfigMenu"
				:offeringId="offeringId"
			/>
			<CfrFeatureAccordion
				v-if="shouldShowFeatures"
				:isPreAnnounced="isPreAnnounced"
			/>
			<LoadingResults
				v-if="isLoadingPreconfig"
				:error-text="$t('loadingResults.errorText')"
				:loading-text="$t('loadingResults.loadingText')"
				:state="'LOADING'"
			/>
		</div>
		<div v-if="shouldShowFeatures" class="cfr-total-amount flex justify-flex-end l-pa-2">
			<i18n path="cfr.totalAmount">
				<strong>
					{{ totalAmount.value | formatToMoney(totalAmount.currency) }}
				</strong>
			</i18n>
		</div>
		<div class="bx--modal-footer">
			<CvButton
				kind="secondary"
				:class="{ 'block-action': generatingCfr }"
				:disabled="generatingCfr"
				@click="cancelCfr"
			>
				{{ $t('cfr.cancel') }}
			</CvButton>
			<CvButton
				class="flex justify-between pr-5"
				:class="{ 'block-action': generatingCfr }"
				:disabled="cfrParsedFeatures.length === 0"
				@click="createFile"
			>
				<span>{{ $t('cfr.create') }}</span>
				<CvInlineLoading v-show="generatingCfr" state="loading" loading-text="" />
			</CvButton>
			<LoadPreConfigModal
				:isOpen="isModalOpen"
				:label="$t('cfr.generate')"
				@primary-click="submit"
				@click="togglePreConfigModal(true)"
				@close="togglePreConfigModal(false)"
			/>
		</div>
	</nav>
</template>

<script>
	import { mapGetters, mapMutations, mapState } from 'vuex';
	import featuresTypes from '@/store/modules/features/mutations/types';
	import offeringMutationTypes from '@/store/modules/offering/mutations/types';
	import preAnnouncementTypes from '@/store/modules/preAnnouncement/mutations/types';

	import { EventBus, downloadFile, actionMonitoring } from '@/utils';

	import { IconClose, IconTrashCan } from '@/carbon/icons';

	import cfrServices from '@/services/cfr';
	import LoadingResults from '@/components/LoadingResults/LoadingResults.vue';

	import { eConfigLink } from '@/constants';

	import CfrFeatureAccordion from './Accordion/CfrFeatureAccordion.vue';
	import PreConfiguredList from './List/PreConfiguredList.vue';
	import LoadPreConfigModal from './LoadPreConfigModal/LoadPreConfigModal.vue';

	export default {
		name: 'CfrCreation',
		components: {
			CfrFeatureAccordion,
			PreConfiguredList,
			LoadingResults,
			LoadPreConfigModal,
		},
		data() {
			return {
				generatingCfr: false,
				isModalOpen: false,
			};
		},
		computed: {
			...mapGetters({
				isPreAnnouncedOffering: 'PreAnnouncement/isPreAnnouncedOffering',
				payloadFeatures: 'Features/cfrPayloadFeatures',
				cfrFeaturesWithQuantities: 'Features/cfrFeaturesWithQuantities',
			}),
			...mapState('Features', {
				cfrParsedFeatures: ({ cfrParsedFeatures }) => cfrParsedFeatures,
				isCustomized: ({ isCustomized }) => isCustomized,
			}),
			...mapState('PreAnnouncement', {
				isLoadingPreconfig: ({ isLoadingPreconfig }) => isLoadingPreconfig,
				preConfiguration: ({ preConfiguration }) => preConfiguration,
			}),
			...mapState('Prices', {
				offeringBasePrice: ({ offeringListPrice }) => offeringListPrice,
			}),
			...mapState('User', {
				promotionalOfferings: ({ promotionalOfferings }) => promotionalOfferings,
			}),
			offeringId() {
				return this.$route.params?.id;
			},
			countryISOCode() {
				return this.$route.query?.country;
			},
			isPreAnnounced() {
				return this.isPreAnnouncedOffering(this.offeringId);
			},
			shouldShowPreConfigMenu() {
				return this.isPreAnnounced && !this.preConfiguration.id && !this.isLoadingPreconfig;
			},
			shouldShowFeatures() {
				return !this.isPreAnnounced || (this.preConfiguration.id && !this.isLoadingPreconfig);
			},
			totalAmount() {
				let currency = null;
				let total = this.cfrParsedFeatures.reduce((acc, feature) => {
					if (feature.price !== '') {
						if (!currency && feature.currency !== '') {
							// eslint-disable-next-line prefer-destructuring
							currency = feature.currency;
						}

						return acc + parseFloat(feature.price) * feature.quantity;
					}

					return acc;
				}, 0);

				total += this.offeringBasePrice.value;
				currency = currency || this.offeringBasePrice.currency;

				return { value: total, currency };
			},
		},
		created() {
			Object.assign(this, { IconClose, IconTrashCan });

			EventBus.$on(
				'update:clear-cfr-creation',
				() => this.clearSelectedFeatures(),
			);
		},
		methods: {
			...mapMutations('PreAnnouncement', {
				setPreConfiguration: preAnnouncementTypes.SET_PRECONFIGURATION,
			}),
			...mapMutations('Features', {
				clearSelectedFeatures: featuresTypes.CLEAR_SELECTED_FEATURES,
				setIsCustomized: featuresTypes.SET_IS_CUSTOMIZED,
			}),
			...mapMutations('Offering', {
				setCfrCreationIsExpanded: offeringMutationTypes.SET_IS_CFR_CREATION_EXPANDED,
			}),
			cancelCfr() {
				if (!this.generatingCfr) {
					this.closeMenu();
					this.clearSelectedFeatures();
					this.setPreConfiguration({});
				}
			},
			closeMenu() {
				this.setCfrCreationIsExpanded(false);
				this.togglePreConfigModal(false);
			},
			togglePreConfigModal(flag) {
				this.setCfrCreationIsExpanded(flag);
				this.isModalOpen = flag;
			},
			createFile() {
				const isPreConfig = this.preConfiguration.id
					&& this.preConfiguration.id !== 'user-new-configuration-list';

				if (isPreConfig && this.promotionalOfferings?.includes(this.offeringId)) {
					this.submit('eConfig');
				} else if (isPreConfig) {
					this.closeMenu();

					this.togglePreConfigModal(true);
				} else {
					this.submit('download');
				}
			},
			async submit(option) {
				const invalidOfferingId = !this.offeringId;
				const invalidFeatures = this.cfrParsedFeatures?.length === 0;

				let routeService;

				if (invalidOfferingId || invalidFeatures || this.generatingCfr) {
					return;
				}

				if (this.preConfiguration.id === 'user-new-configuration-list') {
					this.preConfiguration.id = null;
				}

				if (option === 'download') {
					routeService = cfrServices.createCfrFile;
				} else if (option === 'eConfig') {
					routeService = cfrServices.saveCfrFile;
				}
				try {
					this.generatingCfr = true;

					const featureCodeQuantity = this.cfrFeaturesWithQuantities.length.toString();
					const isCustomized = this.isCustomized.toString();

					const response = await routeService(
						this.preConfiguration.id,
						this.offeringId,
						this.countryISOCode,
						this.cfrFeaturesWithQuantities,
						this.isCustomized,
					);

					let actionProperties = {
						offeringiddyna: this.offeringId,
						featurecodequantity: featureCodeQuantity,
						countrycode: this.countryISOCode,
					};
					actionMonitoring.catchActionAndProperty(actionMonitoring.CFR_CREATION, actionProperties);

					if (this.isPreAnnounced) {
						actionProperties = {
							offeringiddyna: this.offeringId,
							featurecodequantity: featureCodeQuantity,
							countrycode: this.countryISOCode,
							iscustomized: isCustomized,
						};
						actionMonitoring.catchActionAndProperty(actionMonitoring.PRE_CONFIG_CREATED, actionProperties);
					}
					if (option === 'download') {
						const now = new Date().toISOString().split('T')[0];
						const filename = `${this.offeringId}-${now}.cfr`;

						downloadFile(response, filename);
					}

					this.$notify({
						duration: 10000,
						data: {
							kind: 'success',
							title: this.$t(`cfr.notifications.${option}.successTitle`),
							caption: this.$t(`cfr.notifications.${option}.successCaption`, {
								cfrName: response.cfrName,
								url: `${eConfigLink}manage-repository`,
							}),
						},
					});
				} catch (error) {
					if (error instanceof Error) {
						actionMonitoring.reportError(error);
					} else {
						actionMonitoring.reportError(this.$t(`cfr.notifications.${option}.errorTitle`));
					}
					this.$notify({
						duration: 10000,
						data: {
							kind: 'error',
							title: this.$t(`cfr.notifications.${option}.errorTitle`),
							caption: this.$t(`cfr.notifications.${option}.errorCaption`, {
								url: `${eConfigLink}manage-repository`,
							}),
						},
					});
				} finally {
					this.generatingCfr = false;
					this.closeMenu();
				}
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep header .cfr-menu.bx--header-panel--expanded {
		width: #{5 x $spacing-11};
	}
	.cfr-menu {
		height: 100%;
		color: $black-100;
		position: relative;

		.offering-base-information {
			@include carbon--type-style('productive-heading-01');
			font-weight: 400;
			width: auto;
		}

		::v-deep .content {
			overflow-y: scroll;

			ul {
				overflow-y: scroll;
				padding-bottom: $spacing-10;
			}
		}

		#cfr-menu--label {
			@include carbon--type-style('productive-heading-03');
			color: $black-100;
			width: 50em;
		}

		.cfr-total-amount {
			bottom: $spacing-10;
			position: absolute;
			width: 100%;
			z-index: 99;
			background-color: $white-0;
		}

		::v-deep .cfr-close {
			margin: 0 0 0 (-$spacing-03);
			&:hover {
				background-color: #e5e5e5;
			}

			.bx--btn__icon > path {
				fill: $black-100;
			}
		}

		.features-length,
		.features-information {
			font-size: 12px;
		}

		.features-length {
			color: $gray-70;
		}

		.features-information {
			margin: $spacing-07 0;
		}

		::v-deep .remove--button {
			&::before {
				display: none;
			}

			.bx--assistive-text {
				display: none;
			}

			svg > path {
				fill: $gray-100;
			}
		}

		::v-deep .bx--btn--primary,
		::v-deep .bx--btn--secondary {
			&.block-action {
				cursor: not-allowed;
			}
		}

		::v-deep .bx--btn--primary {
			.bx--inline-loading {
				width: $spacing-07;
				height: $spacing-07;

				.bx--loading__background {
					stroke: $blue-60;
				}

				.bx--loading__stroke {
					stroke: $white-0;
				}
			}
		}
	}
</style>
