import types from './types';

export default {
	/**
	 * Adds a configuration to the cart
	 * @param {*} state
	 * @param {object} config configuration to add on cart
	 */
	[types.ADD_CONFIGURATIONS](state, config) {
		state.configurations.push(config);
	},
	/**
	 * Remove the configuration by position on cart
	 * @param {*} state
	 * @param {number} position index of the element in the array
	 */
	[types.REMOVE_CONFIGURATION](state, position) {
		state.configurations.splice(position, 1);
	},
	/**
	 * Sets the configuration list to the cart
	 * @param {*} state
	 * @param {array} configs the array with configurations to show on cart
	 */
	[types.SET_CONFIGURATIONS](state, configs) {
		state.configurations = configs;
	},

	/**
	 * Sets the exapandable that has the cart
	 * @param {*} state
	 * @param {boolean} flag true if is expanded and false if isnt
	 */
	[types.SET_IS_TEMPLATE_CART_EXPANDED](state, flag) {
		state.isTemplateCartExpanded = flag;
	},
	/**
	 * Change the quantity property of configuration object
	 * @param {*} state
	 * @param {number} quantity new value for quantity to this object
	 * @param {number} position index of the element in the array
	 */
	[types.SET_QUANTITY](state, { quantity, position }) {
		state.configurations[position].quantity = quantity;
	},
	/**
	 * Change the status(state) to reflect on configuration picker page itself
	 * @param {*} state
	 * @param {number} newStatus new status
	 */
	[types.CHANGE_STATUS](state, newStatus) {
		state.status = newStatus;
	},
};
