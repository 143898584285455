import {
	parseCurrencyValue,
	formatDate,
	parseYesOrNo,
	createTablePageSizes,
} from '@/utils';

/**
 * Convert upgrade prices to 2d array representing a table
 *
 * @param {object} state
 * @param {object} state.upgradePrices
 * @param {array} state.upgradePrices.data
 * @returns {object}
 */
export function upgradePricesToTable({ upgradePrices }) {
	if (!Array.isArray(upgradePrices.data)) {
		return { columns: [], data: [] };
	}

	const { currency } = upgradePrices.data
		.find(price => price.currency) || {};

	return {
		columns: [
			'From Offering',
			'To Offering',
			'From Feature',
			'To Feature',
			'Price Type',
			'Price Description',
			`Price (${currency})`,
			'Start Date',
			'End Date',
		],
		data: upgradePrices.data.map(price => [
			price.fromOfferingId || '-',
			price.toOfferingId || '-',
			price.fromFeatureCode || '-',
			price.toFeatureCode || '-',
			price.priceType || '-',
			price.description || '-',
			parseCurrencyValue(price.priceValue),
			formatDate(price.startDate),
			formatDate(price.endDate),
		]),
	};
}

/**
 * Convert maintenance prices to 2d array representing a table
 *
 * @param {object} state
 * @param {object} state.maintenancePrices
 * @param {array} state.maintenancePrices.data
 * @returns {object}
 */
export function maintenancePricesToTable({ maintenancePrices }) {
	if (!Array.isArray(maintenancePrices.data)) {
		return { columns: [], data: [] };
	}

	const { currency } = maintenancePrices.data
		.find(price => price.currency) || {};

	return {
		columns: [
			'Product',
			'Description',
			'Price Type',
			'Price Description',
			`Price (${currency})`,
			'Start Date',
			'End Date',
		],
		data: maintenancePrices.data.map(price => [
			price.offeringId || price.featureCode || '-',
			price.description || '-',
			price.priceType || '-',
			price.priceDescription || '-',
			parseCurrencyValue(price.priceValue),
			formatDate(price.startDate),
			formatDate(price.endDate),
		]),
	};
}

/*
* @param {object} state
* @param {object} state.shippingHandlingPrices
* @param {array} state.shippingHandlingPrices.data
* @returns {object}
*/
export function shippingHandlingPricesToTable({ shippingHandlingPrices }) {
	if (!Array.isArray(shippingHandlingPrices.data)) {
		return { columns: [], data: [] };
	}

	const { currency } = shippingHandlingPrices.data
		.find(price => price.currency) || {};
	return {
		columns: [
			'Product',
			'Description',
			'Price Type',
			'Price Description',
			`Price (${currency})`,
			'Start Date',
			'End Date',
		],
		data: shippingHandlingPrices.data.map(price => [
			price.offeringId || price.featureCode || '-',
			price.description || '-',
			price.priceType || '-',
			price.priceDescription || '-',
			parseCurrencyValue(price.priceValue),
			formatDate(price.startDate),
			formatDate(price.endDate),
		]),
	};
}

/**
 * Convert list prices to 2d array representing a table
 *
 * @param {object} state
 * @param {object} state.listPrices
 * @returns {object}
 */
export function listPricesToTable(
	{ listPrices, offeringListPrice },
	_,
	rootState,
) {
	if (!Array.isArray(listPrices.data)) {
		return {
			columns: [],
			data: [],
		};
	}

	const offeringId = rootState.Offering.offering?.offeringId;
	const countryCode = rootState.Countries.selected?.isoCode;
	const qs = `offeringId=${offeringId}&country=${countryCode}`;

	const currency = offeringListPrice.currency
		|| listPrices.data.find(price => price.currency)?.currency
		|| '';

	const data = listPrices.data.map((price) => {
		const isOfferingRow = !price.featureCode;
		const link = isOfferingRow
			? null
			// Feature code may contain '/' (e.g. "N/A")
			: `/feature/${encodeURIComponent(price.featureCode)}?${qs}`;

		const row = [
			isOfferingRow ? price.offeringId : price.featureCode,
			price.description || '-',
			price.featureCategory || '-',
			price.customerSetup || '-',
			parseYesOrNo(price.zeroPricedIndicator === null ? false : price.zeroPricedIndicator),
			parseYesOrNo(price.pricedIndicator === null ? false : price.pricedIndicator),
			parseCurrencyValue(price.priceValue),
			formatDate(price.availabilityDate),
			link,
		];

		if (isOfferingRow) {
			row.inactive = true;
		}

		return row;
	});

	return {
		columns: [
			'Product',
			'Description',
			'Feature Category',
			'Customer Setup',
			'Zero Priced',
			'Priced Indicator',
			`List Price ${currency ? `(${currency})` : ''}`,
			'Availability',
			'Option',
		],
		data,
	};
}

/**
 * Convert state's pagination object to 'CvDataTable'
 * pagination object
 *
 * @param {state} state
 * @param {state} state.pagination
 * @param {state} state.total
 * @returns {object}
 */
export function listPricePaginationToTable({ listPrices }) {
	const { pagination, total } = listPrices;

	return {
		numberOfItems: total,
		page: pagination.page,
		pageSizes: createTablePageSizes(pagination.size),
	};
}
