<template>
	<notifications :group="group">
		<template slot="body" slot-scope="props">
			<CvToastNotification
				:title="props.item.data.title"
				:sub-title="props.item.data.subtitle"
				:caption="props.item.data.caption"
				:kind="props.item.data.kind"
				:close-aria-label="props.item.data.closeLabel"
				@close="props.close"
			>
			</CvToastNotification>
		</template>
	</notifications>
</template>

<script>
	import VueTypes from 'vue-types';

	export default {
		name: 'ToastNotification',
		props: {
			group: VueTypes.string.def(''),
		},
	};
</script>

<style lang="scss">
	#app {
		.vue-notification-group {
			z-index: 99999;
			top: $spacing-09 !important;
			width: 20rem !important;

			.bx--toast-notification {
				margin-top: 0;
				margin-bottom: 0;
				width: 100%;
			}
		}
	}
</style>
