const readFile = file => new Promise((resolve, reject) => {
	const reader = new FileReader();

	reader.onload = () => {
		resolve(reader.result);
	};

	reader.onerror = reject;

	reader.readAsText(file);
});

export default readFile;
