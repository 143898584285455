<template>
	<CvSwitcher v-if="state == States.Loaded">
		<CvSwitcherItem v-for="link in usefulLinks" :key="link.id">
			<CvSwitcherItemLink
				:href="link.url"
				target="_blank"
				rel="noopener noreferrer"
			>
				{{ link.label }} <span class="sr-only">{{ $t('accessibility.opensInNewTab') }}</span>
			</CvSwitcherItemLink>
		</CvSwitcherItem>
	</CvSwitcher>
	<CvSwitcher v-else-if="state == States.Loading">
		<CvSwitcherItem class="useful-links-message">
			<span>{{ $t('usefulLinks.loading') }}</span>
		</CvSwitcherItem>
	</CvSwitcher>
	<CvSwitcher v-else>
		<CvSwitcherItem class="useful-links-message">
			<span>{{ $t('usefulLinks.error') }}</span>
		</CvSwitcherItem>
	</CvSwitcher>
</template>

<script>
	import cmsService from '@/services/cms';

	const States = Object.freeze({
		Loading: 1,
		Loaded: 2,
		Error: 3,
	});

	export default {
		name: 'UsefulLinksPanel',
		data() {
			return {
				state: States.Loading,
				usefulLinks: [],
				States,
			};
		},
		async mounted() {
			try {
				this.state = States.Loading;

				this.usefulLinks = await cmsService.getUsefulLinks();

				this.state = States.Loaded;
			} catch (error) {
				this.usefulLinks = [];
				this.state = States.Error;
			}
		},
	};
</script>

<style lang="scss" scoped>
	.useful-links-message {
		padding: 0.375rem 1rem;
	}
</style>
