import getters from './getters';
import actions from './actions';
import mutations from './mutations';

// TODO: get data from an API
const offerings = window.catalogConfig.PRE_ANNOUNCED_OFFERINGS?.split(',') || [];

export default {
	namespaced: true,

	state() {
		return {
			availablePreAnnouncedOfferings: offerings.map(o => o.trim()),
			preConfiguration: {
				id: '',
				name: '',
				description: '',
			},
			isLoadingPreconfig: false,
			preConfigurationList: [],
			configurationsToCompare: [],
			comparisonSpecType: null,
		};
	},
	mutations,
	getters,
	actions,
};
