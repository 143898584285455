<template>
	<div class="loading-message">
		<CvInlineLoading
			:ending-text="endingText"
			:error-text="errorText"
			:loading-text="loadingText"
			:loaded-text="loadedText"
			:state="state"
		/>
	</div>
</template>

<script>
	import VueTypes from 'vue-types';

	export default {
		name: 'LoadingResults',
		props: {
			endingText: VueTypes.string.def(''),
			errorText: VueTypes.string.def(''),
			loadingText: VueTypes.string.def(''),
			loadedText: VueTypes.string.def(''),
			state: VueTypes.string.isRequired,
		},
	};
</script>

<style lang="scss" scoped>
	.loading-message {
		display: flex;
		justify-content: center;
		align-items: center;
		flex: 1;

		.bx--inline-loading {
			width: unset;
			padding-right: 5px;
		}
	}

	::v-deep .bx--loading {
		animation-duration: 1100ms;
	}
</style>
