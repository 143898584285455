import { mapState, mapMutations } from 'vuex';
import types from '@/store/modules/countries/mutations/types';

import { formatDate, getObjectPropertyValue } from '@/utils';

export { parentTabMixin } from './parentTabMixin';
export { holdContentLoadUntilMountedMixin } from './holdContentLoadUntilMountedMixin';

export const selectedCountryIsoCodeMixin = {
	data() {
		return {
			hasNewCountry: false,
		};
	},
	computed: {
		...mapState('Countries', {
			selectedCountryIsoCode: ({ selected }) => selected.isoCode,
			countriesForSelection: ({ list }) => list,
		}),
	},
};

export const selectedCountryNameMixin = {
	computed: {
		...mapState('Countries', {
			selectedCountryName: ({ selected }) => selected.name,
		}),
	},
};

export const accordionDetailsMixin = {
	computed: {
		accordionDetails() {
			const gridIds = this.grid.map(row => row.split(' ').filter(col => col.match(/\w/gi))).flat(Infinity);

			return gridIds.map(id => ({
				id,
				label: this.$t(`${this.labelType}.${id}`),
				value: this.needFormatDate.includes(id) ? formatDate(this.details[id]) : this.details[id],
			}));
		},
	},
};

export const tableAccordionMixin = {
	computed: {
		gridDetails() {
			return this.grid.map(id => (
				{
					id,
					label: this.$t(`${this.labelType}.${id}`),
					value: this.needFormatDate.includes(id)
						? formatDate(this.details[0][id]) : this.details[0][id],
				}));
		},

		tableColumns() {
			return this.columns.map(columnName => this.$t(`${this.labelType}.${columnName}`));
		},
		tableRows() {
			const newDetails = this.details.map((item) => {
				const temp = { ...item };
				temp.period = `${temp.period} ${temp.uomPeriod}`;
				if (temp.isDefault) {
					temp.isDefault = this.$t(`${this.labelType}.applicable.worldWide`);
				} else if (temp.isDefault === null) {
					temp.isDefault = this.$t(`${this.labelType}.applicable.null`);
				} else {
					temp.isDefault = this.$t(`${this.labelType}.applicable.countrySpecific`);
				}
				return temp;
			});
			return newDetails.map(row => this.columns.map(id => row[id]));
		},
	},
};

// Used to format details information on Offering and Feature pages
export const detailsFormatMixin = {
	methods: {
		formatValue(value, key, isDateReference) {
			if ([null, undefined, ''].includes(value)) {
				return '-';
			}

			if (typeof value === 'boolean') {
				return value ? 'Yes' : 'No';
			}

			return isDateReference.includes(key) ? formatDate(value) : value;
		},
		createDetailsItems(i18nPath, data, isDateReference = []) {
			return Array.from(data)
				.map(([key, value]) => ({
					label: this.$t(`${i18nPath}.${key}`),
					value: this.formatValue(value, key, isDateReference),
				}));
		},
		createDetailsMapItem(model, data, i18nPath, i18nValuesPath) {
			return model.map((key) => {
				const dataValue = data?.[key];

				if (dataValue) {
					const value = i18nValuesPath.includes(key)
						? this.$t(`${i18nPath}.values.${key}.${dataValue}`)
						: dataValue;

					return [key, value];
				}

				return [key, dataValue];
			});
		},
		createDetails(config) {
			const {
				model,
				data,
				dateReference,
				i18nPath,
				i18nValuesPath = [],
			} = config;

			// Ensure the exhibition order is correct
			const details = new Map(this.createDetailsMapItem(model, data, i18nPath, i18nValuesPath));

			return this.createDetailsItems(i18nPath, details, dateReference);
		},
	},
};

// Used to format header on ESW page
export const scrollMixin = {
	data() {
		return {
			hasToHideItems: false,
			scrollDebounceTimer: null,
		};
	},
	mounted() {
		this.handleScroll();
		window.addEventListener('scroll', this.handleScroll);
	},
	unmounted() {
		window.removeEventListener('scroll', this.handleScroll);
	},
	methods: {
		updateVisibility() {
			const oldValue = this.hasToHideItems;

			if (window.scrollY < 80) {
				this.hasToHideItems = false;
			} else {
				this.hasToHideItems = true;
			}

			this.scrollDebounceTimer = null;
			if (oldValue !== this.hasToHideItems) {
				this.initDebounce();
			}
		},
		initDebounce() {
			this.scrollDebounceTimer = setTimeout(() => {
				this.updateVisibility();
			}, 50);
		},
		handleScroll() {
			if (this.scrollDebounceTimer) {
				return;
			}

			this.updateVisibility();
		},
	},
};

export const offeringAndCountryCheckMixin = {
	mixins: [
		selectedCountryIsoCodeMixin,
	],
	computed: {
		queryCountry() {
			return this.$route.query?.country || '';
		},
	},
	methods: {
		...mapMutations('Countries', {
			setSelectedCountry: types.SET_SELECTED_COUNTRY,
		}),
		isValidOfferingAndCountrySearch() {
			if (!this.validateOfferingId()) {
				return false;
			}
			if (!this.validateCountry()) {
				return false;
			}
			if (!this.isSelectedCountry()) {
				this.hasNewCountry = true;
				this.setSelectedCountry(this.queryCountry);
				return true;
			}
			return true;
		},
		validateOfferingId() {
			if (!this.offeringId) {
				this.$notify({
					data: {
						kind: 'warning',
						title: this.$t('featureDetails.notifications.offeringIdNotFoundTitle'),
						subtitle: this.$t('featureDetails.notifications.offeringIdNotFoundSubtitle'),
					},
				});
				return false;
			}
			return true;
		},
		validateCountry() {
			if (!this.queryCountry) {
				this.$notify({
					data: {
						kind: 'warning',
						title: this.$t('details.notifications.countryNotFoundTitle'),
						subtitle: this.$t('details.notifications.countryNotFoundSubtitle'),
					},
				});
				return false;
			}
			const countryExists = this.countriesForSelection.find(c => c.isoCode === this.queryCountry);
			if (!countryExists) {
				this.$notify({
					data: {
						kind: 'warning',
						title: this.$t('details.notifications.countryNotFoundTitle'),
						subtitle: this.$t('details.notifications.countryNotFoundSubtitle'),
					},
				});
				return false;
			}

			return true;
		},
		isSelectedCountry() {
			if (this.selectedCountryIsoCode.toUpperCase() !== this.queryCountry.toUpperCase()) {
				return false;
			}

			return true;
		},
	},
};

export const breadcrumbMixin = {
	computed: {
		breadcrumbData() {
			const { breadcrumb } = this.$route.meta;
			return breadcrumb?.map((data) => {
				const label = data.isLabelI18n
					? this.$t(data.labelKey) : getObjectPropertyValue(this, data.labelKey);
				let { link } = data;
				data.linkKeys.forEach((key) => {
					link = link.replace(key.replaceableKey, getObjectPropertyValue(this, key.value));
				});

				// Remove garbage from url when user returns to homepage through browser back button
				const searchParams = new URLSearchParams(link);
				if (searchParams.has('type')) {
					if (!searchParams.get('/?search')) {
						link = '/';
					}
				}

				return {
					label,
					link,
				};
			});
		},
	},
};

/**
 * Tabs header (CvTabs) are composed of anchors, which by default,
 * are draggable. This is not a desired behavior for tabs, the last
 * vue-carbon version replace anchors for buttons. So while we
 * don't update our vue-carbon component, forcing all headers
 * to not be draggable
 */
export const undraggableTabsMixin = {
	methods: {
		removeDraggableFromTabs(tabs) {
			if (tabs) {
				const anchors = tabs.querySelectorAll('a.bx--tabs__nav-link');
				[...anchors].forEach(anchor => anchor.setAttribute('draggable', 'false'));
			}
		},
	},
};
