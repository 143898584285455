import JSZip from 'jszip';
import { format } from 'date-fns';
import { saveAs } from 'file-saver';

const zip = new JSZip();

const compressionOptions = {
	type: 'blob',
	compression: 'DEFLATE',
	compressionOptions: {
		level: 5,
	},
	streamFiles: true,
};

const responseType = new Map([
	['txt', 'text/plain'],
	['html', 'text/plain'],
	['csv', 'text/csv'],
	['rtf', 'text/rtf'],
]);

const createFile = (filename, content) => zip.file(filename, content);

const createFiles = (files, extension) => {
	files.forEach((file) => {
		const filename = file.fileName.replace(/(.*)\.[^.]+$/, `$1.${extension}`);
		createFile(filename, file.fileContent);
	});
};

const compressFiles = () => {
	const datetime = format(new Date(), 'MM/dd/yyyy kk:mm:ss');
	const downloadFileName = `converted-cfrs-${datetime}.zip`.replace(/[/: ]/g, '');
	zip.generateAsync(compressionOptions)
		.then((content) => {
			saveAs(content, downloadFileName);
		});
};

const writeFiles = async (files, extension) => {
	if (files.length === 1) {
		const fileName = files[0].fileName.replace('.cfr', `.${extension}`);
		const blobFile = new Blob([files[0].fileContent], { type: `${responseType.get(extension)};charset=utf-8` });
		saveAs(blobFile, fileName);
	} else {
		createFiles(files, extension);
		await compressFiles();
	}
};

export default writeFiles;
