import mutations from './mutations';
import * as actions from './actions';
import * as getters from './getters';

export default {
	namespaced: true,

	state() {
		return {
			offeringListPrice: {
				value: 0,
				currency: null,
			},
			listPrices: {
				data: null,
				total: 0,
				loaded: false,
				pagination: {
					page: 1,
					size: 25,
					search: null,
				},
			},
			maintenancePrices: {
				data: null,
				total: 0,
				loaded: false,
			},
			shippingHandlingPrices: {
				data: null,
				total: 0,
				loaded: false,
			},
			upgradePrices: {
				data: null,
				total: 0,
				loaded: false,
			},
		};
	},
	actions,
	getters,
	mutations,
};
