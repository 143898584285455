import mutationsTypes from '../../mutations/types';

export default (
	{
		commit,
		rootState: { PreAnnouncement: { configurationsToCompare } },
	},
	config,
) => {
	try {
		const configs = configurationsToCompare;
		const newList = configs.filter(stateConfig => stateConfig !== config);

		commit(mutationsTypes.SET_CONFIGURATIONS_TO_COMPARE, newList);
		if (newList.length === 0) {
			commit(mutationsTypes.SET_COMPARISON_SPEC_TYPE, null);
		}
	} catch (error) {
		return error;
	}
};
