module.exports = {
	brand: [
		/^.{2,80}$/,
	],
	offeringId: [
		/^[a-zA-Z0-9]{1,6}[*]$/, // regex search input ends with asterisk
		/^[*][a-zA-Z0-9]{1,6}$/, // regex search input starts with asterisk
		/^[*][a-zA-Z0-9]{1,5}[*]$/, // regex search input with wrapped asterisk
		/^[a-zA-Z0-9]{7}$/, // regex search normal input with length 7
		/^[a-zA-Z0-9]{4}[-]{0,1}[a-zA-Z0-9]{3}$/, // regex search input with format machineType-machineCode
	],
	offeringDescription: [
		/^.{2,80}$/,
	],
	featureCode: [
		/^[a-zA-Z0-9]{4,6}$/,
	],
	featureDescription: [
		/^.{2,80}$/,
	],
	offeringIdFeatureCode: [
		/^[a-zA-Z0-9]{8,13}$/,
	],
};
