/**
 * **This mixin should only be used by components that are
 * directly children of CvTabs that has CvTab as child**
 *
 * CvTabs and, its child, CvTab works by child telling the
 * parent its state via events which CvTab directly call from
 * the parent element ($parent). Here, we're 'repassing' such
 * calls from CvTab to CvTabs.
 */
export const parentTabMixin = {
	created() {
		this.$_CvTab = true; // not really used by the CvTabs but here for sanity
		this.$on('cv:mounted', child => this.$parent.$emit('cv:mounted', child));
		this.$on('cv:beforeDestroy', child => this.$parent.$emit('cv:beforeDestroy', child));
		this.$on('cv:selected', child => this.$parent.$emit('cv:selected', child));
		this.$on('cv:disabled', child => this.$parent.$emit('cv:disabled', child));
		this.$on('cv:enabled', child => this.$parent.$emit('cv:enabled', child));
	},
};
