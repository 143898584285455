/**
 * This mixin is a workaround to postpone a particular
 * child component (or children) load so the parent
 * (using this mixin) can load itself faster. This
 * is just for cases where the page feels stuck /
 * frozen because there is just too much elements
 * getting loaded.
 *
 * Use only as a last source and you want to cry
 * because a real option can't be done right now
 *
 * Must be used with 'v-if', which doesn't attach
 * a component in the DOM until you pass true to it
 */
export const holdContentLoadUntilMountedMixin = {
	data() {
		return {
			displayContent: false,
		};
	},
	mounted() {
		setTimeout(() => {
			this.displayContent = true;
		}, 100);
	},
};
