import * as SearchService from '@/services/search';
import { parseSearchValue } from '@/utils/parseSearchValue';
import mutationsTypes from '../../mutations/types';
import validateParameters from './validators';

/**
 * Searches offerings & features based on their characteristics (search type)
 * @param context Store instance's methods and properties
 * @param offeringId Inputted offering's id
 * @param {Function} callback function called once result is received.
 * Payload is passed as first parameter, as its not called on error.
 */
export default async function search({ commit, rootState }, callback) {
	try {
		// Prepares data
		const countryISOCode = rootState.Countries.selected.isoCode;
		const {
			page,
			pageSize,
			searchType,
			searchValue,
		} = rootState.Search;

		// Validate parameters
		validateParameters(
			countryISOCode,
			searchValue,
			searchType,
			pageSize,
			page,
		);

		// Prepares pre-state
		commit(mutationsTypes.SET_LOADING, true);
		commit(mutationsTypes.SET_ERROR, false);
		commit(mutationsTypes.SET_SEARCH_RESULT, []);

		const searchedValue = parseSearchValue(searchValue);

		// Executes the search
		const result = await SearchService.search({
			countryISOCode,
			searchInput: searchedValue,
			searchType,
			pageSize,
			page,
		});

		if (typeof callback === 'function') {
			await callback(result);
		}

		// Commit the result
		commit(mutationsTypes.SET_SEARCH_RESULT, result);
		commit(mutationsTypes.SET_LOADING, false);
	} catch (error) {
		if (error.message !== 'canceled') {
			commit(mutationsTypes.SET_ERROR, true);
			commit(mutationsTypes.SET_LOADING, false);
		}
	}
}
