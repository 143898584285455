<template>
	<div id="app" class="flex flex-column">
		<LoadingResults
			v-if="isLoading || hasError"
			:error-text="errorText"
			:loading-text="$t('loading.application.loadingText')"
			:state="loadingState"
		/>
		<template v-else>
			<CatalogHeader />
			<RouterView />
			<ToastNotification />
			<FooterDisclaimer />
		</template>
	</div>
</template>

<script>
	import { mapState, mapActions } from 'vuex';
	import CatalogHeader from '@/components/CatalogHeader/CatalogHeader.vue';
	import countries from '@/assets/countries.json';
	import ToastNotification from '@/components/ToastNotification/ToastNotification.vue';
	import LoadingResults from '@/components/LoadingResults/LoadingResults.vue';
	import FooterDisclaimer from '@/components/Disclaimer/FooterDisclaimer.vue';
	import { getTickyToken } from './services/ticky/getTickyToken';
	import { REQUEST_STATUS } from './constants';

	export default {
		name: 'App',
		components: {
			ToastNotification,
			CatalogHeader,
			LoadingResults,
			FooterDisclaimer,
		},
		computed: {
			...mapState('User', {
				isLoading: ({ isLoading }) => isLoading,
				hasError: ({ hasError }) => hasError,
				errorCode: ({ errorCode }) => errorCode,
				email: ({ email }) => email,
				country: ({ country }) => country,
			}),
			loadingState() {
				if (this.hasError) return 'ERROR';

				return 'LOADING';
			},
			errorText() {
				const localizationPath = this.errorCode === REQUEST_STATUS.FORBIDDEN
					? 'loading.application.forbiddenErrorText'
					: 'loading.application.authErrorText';

				return this.$t(localizationPath);
			},
		},
		created() {
			this.getUserInfo();
			this.getTicky();
		},
		methods: {
			...mapActions({
				getUserInfo: 'User/getInfo',
			}),
			async getTicky() {
				try {
					if (window.ticky) {
						const response = await getTickyToken();

						if (response?.token) {
							const userCountry = countries.find(c => c.isoCode === this.country);

							window.ticky.setToken(response.token);
							window.ticky.setEmail(this.email);
							window.ticky.setCountry(userCountry.name);
						}
					}
				// eslint-disable-next-line no-empty
				} catch {}
			},
		},
	};
</script>

<style lang="scss">
	html,
	body,
	#app {
		min-height: 100vh;
		width: 100%;
	}
</style>
