import actions from './actions';
import mutations from './mutations';
import getters from './getters';

export default {
	namespaced: true,

	state() {
		return {
			name: '',
			email: '',
			country: '',
			isIbmer: false,
			promotionalOfferings: [],
			hasAccessToPromotionalData: false,
			isLoading: false,
			hasError: false,
			errorCode: null,
		};
	},
	actions,
	mutations,
	getters,
};
