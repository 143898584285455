<template>
	<nav
		:aria-label="$t('cfrConversion.label')"
		class="flex flex-column cfr-menu"
	>
		<div class="content pa-5 flex flex-column">
			<div class="flex justify-between items-center">
				<h2 id="cfr-menu--label-conversion">{{ $t('cfrConversion.title') }} </h2>
				<CvIconButton
					class="cfr-close"
					kind="ghost"
					size="small"
					label="Close"
					:icon="IconClose"
					@click="closeConversion"
				/>
			</div>
			<CvFileUploader
				ref="FileUploader"
				removable
				accept=".cfr"
				multiple
				:files="selectedFiles"
				:label="$t('cfrConversion.fileUploaderLabel')"
				:helperText="$t('cfrConversion.fileUploaderHelperText')"
				@change="onFileUploaderChange"
			/>
			<div class="bx--form-item">
				<span class="bx--label">
					{{ $t('cfrConversion.extensionLabel') }}
				</span>
				<CvRadioGroup class="extensions-list">
					<CvRadioButton
						v-for="(extension, index) in extensions"
						:key="index"
						v-model="conversionExtension"
						:label="extension.label"
						:value="extension.value"
					/>
				</CvRadioGroup>
			</div>
			<div class="bx--form-item">
				<span class="bx--label">
					{{ $t('cfrConversion.overrideLabel') }}
				</span>
				<div class="extensions-list">
					<CvCheckbox
						v-model="conversionUsdOverride"
						value="priceUSD"
						:label="$t('cfrConversion.overrideUSDLabel')"
						name="conversionOverride"
					/>
				</div>
			</div>
		</div>
		<div class="bx--modal-footer">
			<CvButton
				kind="secondary"
				:class="{ 'block-action': convertingFile }"
				:disabled="convertingFile"
				@click="cancelConversion"
			>
				{{ $t('cfrConversion.cancel') }}
			</CvButton>
			<CvButton
				class="flex justify-between pr-5"
				:disabled="invalidConversion"
				:class="{ 'block-action': convertingFile }"
				@click="convertFiles"
			>
				<span>{{ $t('cfrConversion.convert') }}</span>
				<CvInlineLoading v-show="convertingFile" state="loading" loading-text="" />
			</CvButton>
		</div>
	</nav>
</template>

<script>
	import {
		EventBus,
		readFile,
		compressFiles,
		actionMonitoring,
	} from '@/utils';

	import { IconClose } from '@/carbon/icons';

	import cfrServices from '@/services/cfr';

	import FileCountry from './FileCountry';

	export default {
		name: 'CfrFileConversion',
		data() {
			return {
				selectedFiles: [],
				filesContent: [],
				hasInvalidExtension: false,
				hasInvalidCountry: false,
				extensions: [
					{ label: '.txt', value: 'txt' },
					{ label: '.csv', value: 'csv' },
					{ label: '.html', value: 'html' },
					{ label: '.rtf', value: 'rtf' },
					{ label: '.xml', value: 'xml' },
				],
				conversionExtension: 'txt',
				conversionUsdOverride: false,
				convertingFile: false,
				countries: [],
			};
		},
		computed: {
			invalidSelectedFiles() {
				return !this.selectedFiles || this.selectedFiles?.length === 0;
			},
			invalidFilesContent() {
				return !this.filesContent || !this.filesContent?.length === 0;
			},
			invalidFile() {
				return this.invalidSelectedFiles || this.invalidFilesContent;
			},
			invalidConversion() {
				return this.invalidFile || this.hasInvalidExtension || this.hasInvalidCountry;
			},
		},
		created() {
			Object.assign(this, { IconClose });
		},
		methods: {
			closeConversion() {
				EventBus.$emit('update:expand-cfr-file-conversion', false);
			},
			resetFileInput() {
				const input = document.querySelector('.bx--file-input');
				input.setAttribute('type', '');
				input.setAttribute('type', 'file');
			},
			validateCfrExtension(fileName) {
				return !!fileName.match(/(\.cfr)$/i);
			},
			clearFiles() {
				this.selectedFiles = [];
				this.filesContent = [];
			},
			cancelConversion() {
				this.clearFiles();
				this.resetFileInput();
				this.closeConversion();
			},
			setStateOfFile(index, status) {
				this.$refs.FileUploader.setState(index, status);
			},
			clearFilesState(totalFiles) {
				for (let i = 0; i < totalFiles; i += 1) {
					this.$refs.FileUploader.setState(i, '');
				}
			},
			sendCountryInfo(country) {
				const actionProperties = {
					conversionextensiondyna: this.conversionExtension,
					countryname: country,
				};
				actionMonitoring.catchActionAndProperty(actionMonitoring.CFR_CONVERSION, actionProperties);
			},
			sendNotification(totalCorrectFiles, shouldFlagNotify) {
				if (totalCorrectFiles === 0) {
					this.$notify({
						data: {
							kind: 'error',
							title: this.$t('cfrConversion.notifications.errorTitle'),
							caption: this.$t('cfrConversion.notifications.errorCaption'),
						},
					});
					return;
				}

				if (shouldFlagNotify) {
					this.$notify({
						data: {
							kind: 'warning',
							title: this.$t('cfrConversion.notifications.warningTitle'),
							caption: this.$t('cfrConversion.notifications.warningCaption'),
						},
					});
					return;
				}

				this.$notify({
					data: {
						kind: 'success',
						title: this.$t('cfrConversion.notifications.successTitle'),
						caption: this.$t('cfrConversion.notifications.successCaption'),
					},
				});
			},
			parseErrorMessage(apiResponse = {}) {
				const { errorMessage, transactionId, errorType } = apiResponse;
				const formattedId = transactionId ? `(${transactionId})` : '';

				if (errorMessage?.toLowerCase() === 'incorrect locale information provided') {
					actionMonitoring.reportError(errorMessage);
					return `${this.$t('cfrConversion.errorMessage.incorrectLocaleProvided')} ${formattedId}`;
				}

				actionMonitoring.reportError(errorMessage);

				return this.$t(`cfrConversion.errorMessage.${errorType}`, { id: formattedId });
			},
			async convertFiles() {
				if (this.invalidConversion || this.convertingFile) {
					return;
				}
				this.convertingFile = true;
				const convertedFiles = [];

				let shouldFlagNotify = false;
				for (let i = 0; i < this.selectedFiles.length; i += 1) {
					this.setStateOfFile(i, 'uploading');
					try {
						const file = {};
						/* eslint-disable */
						file.fileName = this.selectedFiles[i].file.name;
						file.fileContent = await readFile(this.selectedFiles[i].file);

						const convertedFile = await cfrServices.convertCfrFile({
							conversionExtension: this.conversionExtension,
							overrideToUsd: this.conversionUsdOverride,
							localTime: new Date().toString(),
							file,
						});
						/* eslint-enable */
						if (convertedFile.error) {
							const message = this.parseErrorMessage(convertedFile);

							this.updateInvalidFileMessage(i, message);
							this.setStateOfFile(i, '');
							shouldFlagNotify = true;
						} else {
							this.setStateOfFile(i, 'complete');
							this.sendCountryInfo(this.countries[i]);
							convertedFiles.push(convertedFile);
						}
					} catch {
						this.updateInvalidFileMessage(i, this.parseErrorMessage());
						this.setStateOfFile(i, '');
						shouldFlagNotify = true;
					}
				}

				if (convertedFiles.length > 0) compressFiles(convertedFiles, this.conversionExtension);
				this.sendNotification(convertedFiles.length, shouldFlagNotify);
				this.convertingFile = false;
				this.clearFilesState(this.selectedFiles.length);
			},
			async onFileUploaderChange(userFiles) {
				/**
				 * Needs to reset every time to prevent the bug where
				 * the same file couldn't be added after removed
				 */
				this.resetFileInput();
				this.countries = [];
				this.hasInvalidCountry = false; // Reset to be processed again

				if (userFiles.length === 0) {
					this.clearFiles();
					return;
				}

				/**
				 * This unique files is needed because of the resetFileInput execution
				 * Resetting the input, made the component lose tracking of the files already selected
				 */
				const uniqueFiles = this.removeDuplicateFiles(userFiles);
				this.selectedFiles = uniqueFiles;

				// Save all extension validations to know if all them are valid
				const validExtensionList = [];
				const fileContainer = document.querySelector('.bx--file-container');

				/**
				 * A bug happened when removing a file in an index before an invalid cfr
				 */
				this.removeInvalidDOMCountries(fileContainer);

				// Loop through each file to validate the extension and get the country for it
				this.selectedFiles.forEach((selectedFile, index) => {
					const { file } = selectedFile;

					const fileHasValidExtension = this.validateFileExtension(file, index);
					validExtensionList.push(fileHasValidExtension);

					if (fileHasValidExtension) {
						this.getFileCountry(fileContainer, file, index);
					}
				});

				// Check if all files have a valid extension
				this.hasInvalidExtension = !validExtensionList.every(validExtension => validExtension);
			},
			async getFileCountry(fileContainer, file, index) {
				try {
					const fileContent = await readFile(file);
					const result = await cfrServices.getCountryFromCfr(fileContent);
					const country = result.fullName;
					this.countries.push(country);

					const fileDOM = fileContainer.children[index];
					fileDOM.classList.add('selected-cfr-file');

					const fileDOMIsSelected = this.selectedFiles.find(
						selectedFile => selectedFile.file.name === file.name,
					);

					// Perform only on files that are selected
					if (fileDOMIsSelected) {
						const fileDOMHasCountry = fileDOM.querySelector('.bx--form-requirement');

						// Remove the current country to append the new one
						if (fileDOMHasCountry) {
							fileDOM.removeChild(fileDOM.childNodes[fileDOM.childNodes.length - 1]);
						}

						const Country = FileCountry(this.$t('cfrConversion.country'), country);
						fileDOM.appendChild(Country);
					}
				} catch (error) {
					if (error instanceof Error) {
						actionMonitoring.reportError(error);
					} else {
						actionMonitoring.reportError(this.$t('cfrConversion.countryNotFound'));
					}
					this.updateInvalidFileMessage(index, this.$t('cfrConversion.countryNotFound'));
					this.hasInvalidCountry = true;
				}
			},
			removeDuplicateFiles(files) {
				const uniqueFilesName = new Set(files.map(f => f.file.name));
				const retrieveFileInformation = name => files.find(f => f.file.name === name);

				return Array.from(uniqueFilesName).map(retrieveFileInformation);
			},
			validateFileExtension(file, index) {
				const isExtensionValid = this.validateCfrExtension(file.name);

				if (!isExtensionValid) {
					this.updateInvalidFileMessage(index, this.$t('cfrConversion.invalidExtension'));
				} else {
					this.updateInvalidFileMessage(index, '');
				}

				return isExtensionValid;
			},
			updateInvalidFileMessage(index, message) {
				this.$nextTick(() => {
					this.$refs.FileUploader.setInvalidMessage(index, message);
				});
			},
			removeInvalidDOMCountries(filesContainer) {
				this.$nextTick(() => {
					const doms = filesContainer.querySelectorAll('.bx--file__selected-file--invalid__wrapper');

					doms.forEach((dom) => {
						const countryDom = dom.querySelector(':scope > .bx--form-requirement');

						if (countryDom) {
							dom.removeChild(countryDom);
						}
					});
				});
			},
		},
	};
</script>

<style lang="scss" scoped>
	.cfr-menu {
		height: 100%;
		color: $black-100;

		.files-count {
			font-size: 12px;
			color: $gray-70;
			margin-bottom: $spacing-05;
		}

		.content {
			max-height: calc(100% - 320px);

			::v-deep .cv-file-uploader {
				height: 100%;

				.bx--label-description {
					font-size: 12px;
				}

				.bx--file {
					height: calc(100% - 180px);

					&-container {
						height: calc(100% + 20px);
						overflow-y: scroll;
					}
				}

				.bx--file-complete {
					fill: #42be65;
				}
			}

			& > .bx--form-item {
				margin-top: $spacing-05;

				&:last-child {
					margin-top: $spacing-07;
				}
			}
		}

		#cfr-menu--label-conversion {
			@include carbon--type-style('productive-heading-03');
			color: $black-100;
		}

		::v-deep .cfr-close {
			margin: 0 0 0 -0.4375rem;

			&:hover {
				background-color: #e5e5e5;
			}

			.bx--btn__icon > path {
				fill: $black-100;
			}
		}

		::v-deep .bx--btn--primary,
		::v-deep .bx--btn--secondary {
			&.block-action {
				cursor: not-allowed;
			}
		}

		::v-deep .bx--radio-button-group {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
		}

		::v-deep .selected-cfr-file {
			padding: $spacing-05 0;

			.bx--form-requirement {
				padding-top: $spacing-05;
				border-top: 1px solid $gray-20;

				&__title,
				&__supplement {
					padding: 0 $spacing-05;
				}

				&__title {
					@include carbon--type-style('label-01');
				}

				&__supplement {
					@include carbon--type-style('helper-text-01');
				}
			}
		}
	}

	::v-deep .bx--inline-loading {
		margin-left: 10px;
	}

	::v-deep .bx--loading {
		animation-duration: 1100ms;
	}
</style>
