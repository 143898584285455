export default {
	SET_SELECTED_FEATURES: 'SET_SELECTED_FEATURES',
	CLEAR_SELECTED_FEATURES: 'CLEAR_SELECTED_FEATURES',
	REMOVE_SELECTED_FEATURE: 'REMOVE_SELECTED_FEATURE',
	UPDATE_FEATURE_QUANTITY: 'UPDATE_FEATURE_QUANTITY',
	PARSE_FEATURE_TO_CFR_FORMAT: 'PARSE_FEATURE_TO_CFR_FORMAT',
	REMOVE_CFR_PARSED_FEATURE: 'REMOVE_CFR_PARSED_FEATURE',
	SET_IS_CUSTOMIZED: 'SET_IS_CUSTOMIZED',
	SET_FEATURES: 'SET_FEATURES',
	SET_TOTAL_FEATURES: 'SET_TOTAL_FEATURES',
	SET_PAGINATION: 'SET_PAGINATION',
	FLUSH_FEATURES: 'FLUSH_FEATURES',
};
