import axios from '@axios/axios';
import { parseSearchValue } from '@/utils/parseSearchValue';

/**
 * Searches an offering by its ID and country availability
 * @param offeringId Offering's id
 * @param countryISOCode Country's ISO Code
 * @returns Promise of offerings' search result
 */
export default async (offeringId, countryISOCode) => {
	const searchedValue = parseSearchValue(offeringId);

	const url = `/offerings/${searchedValue}`;

	const options = {
		params: {
			countryISOCode,
		},
	};

	const result = await axios.get(url, options);
	// @TODO: this is a workaround to not block user
	// visibility from maintenance offerings. Shame
	// on us if not treated accordingly (2021-02-17)
	if (result.productType === 'Maintenance') {
		result.productType = 'Hardware';
	}

	return result;
};
