import { render, staticRenderFns } from "./ConfigurationPickerPageLayout.vue?vue&type=template&id=d4daa186&scoped=true"
import script from "./ConfigurationPickerPageLayout.vue?vue&type=script&lang=js"
export * from "./ConfigurationPickerPageLayout.vue?vue&type=script&lang=js"
import style0 from "./ConfigurationPickerPageLayout.vue?vue&type=style&index=0&id=d4daa186&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4daa186",
  null
  
)

export default component.exports