import axios from '@axios/axios';

/**
 * @typedef ReportMetadata
 * @property {string} id
 * @property {'ready' | 'processing' | 'no_data' | 'error'} status
 */

/**
 * Get report information based on user's
 * search
 * @param {object} params
 * @param {string} params.countryISOCode
 * @param {string} params.mimeType
 * @param {string} params.query
 * @param {string} params.priceType
 * @param {string} params.searchType
 * @returns {Promise<ReportMetadata>}
 */
export function getReportStatus(params) {
	const now = new Date().getTime();
	return axios.get('/report', { params: { ...params, now } });
}

/**
 * Get report's file content
 * @param {string} reportId
 * @returns {Blob}
 */
export function downloadReport(reportId) {
	return axios.get(
		`/report/download/${reportId}`,
		{ responseType: 'blob' },
	);
}
