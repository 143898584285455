import axios from '@axios/axios';

export function getUpgradePrices(offeringId, countryISOCode) {
	return axios.get(
		'/prices/upgrade',
		{ params: { offeringId, countryISOCode } },
	);
}

export function getMaintenancePrices(offeringId, countryISOCode) {
	return axios.get(
		'/prices/maintenance',
		{ params: { offeringId, countryISOCode } },
	);
}

export function getShippingHandlingPrices(offeringId, countryISOCode) {
	return axios.get(
		'/prices/shipping',
		{ params: { offeringId, countryISOCode } },
	);
}

export function getListPrices(offeringId, countryISOCode, page, pageSize, search) {
	const url = '/prices/list';

	const options = {
		params: {
			offeringId,
			countryISOCode,
			page: page - 1,
			pageSize,
			search,
		},
	};

	return axios.get(url, options);
}
