/**
 * Validates the inputted offering's id
 * @param offeringId Inputted offering's id
 * @throws When offering's id is invalid
 */
export const validateOfferingId = (offeringId) => {
	if (!offeringId || typeof offeringId !== 'string') {
		throw new Error(`invalid offeringId: '${offeringId}'`);
	}
};

/**
 * Validates the current countryISOCode
 * @param countryISOCode ISO Code of the selected country
 * @throws When countryISOCode is invalid
 */
export const validateCountryISOCode = (countryISOCode) => {
	if (!countryISOCode) {
		throw new Error('there is no country selected');
	}
};

/**
 * Validates the current countryISOCode
 * @param countryISOCode ISO Code of the selected country
 * @throws When countryISOCode is invalid
 */
export const validateSearchType = (searchType) => {
	if (!searchType) {
		throw new Error('there is no type of search selected');
	}
};

/**
 * Validate numbers
 * @param {number} number
 */
export const validateNumber = (number) => {
	if (Number.isNaN(number)) {
		throw new Error(`${number} is not a number`);
	}
};

/**
 * Validates the search parameters
 * @param {string} countryISOCode ISO Code of the selected country
 * @param {string} offeringId Inputted offering's id
 * @param {string} searchType search type
 * @param {number} pageSize pagination page length
 * @param {number} page which page (offset) to fetch
 * @throws When countryISOCode is invalid
 */
export default (countryISOCode, offeringId, searchType, pageSize, page) => {
	validateCountryISOCode(countryISOCode);
	validateOfferingId(offeringId);
	validateSearchType(searchType);
	validateNumber(pageSize);
	validateNumber(page);
};
