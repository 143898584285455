<template>
	<main class="main flex flex-auto flex-column pt-9">
		<slot />
	</main>
</template>

<script>
	export default {
		name: 'LayoutPage',
	};
</script>
