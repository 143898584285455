<template>
	<CvAccordionItem>
		<!--
			An accordion item "title" is a button. Avoid event problems
			by @event.stop'ing it, so the accordion does not open when
			some other button/input is pressed inside.
		-->
		<template slot="title">
			<span>
				{{ feature.featureCode }}
			</span>
			<span class="item-total-price">
				{{ total | formatToMoney(feature.currency) }}
			</span>

			<CounterElement
				:value="feature.quantity"
				:max="feature.maximumAllowed"
				:min="1"
				:aria-label="$t(
					'cfr.counterInputAriaLabel',
					{ code: feature.featureCode },
				)"
				@input="onUpdateQuantity"
			/>

			<CvIconButton
				class="remove--button"
				kind="ghost"
				size="small"
				:aria-label="$t(
					'cfr.removeFeatureItem',
					{ code: feature.featureCode },
				)"
				:icon="IconTrashCan"
				@click.stop="onRemove"
			/>
		</template>

		<template slot="content">
			<p v-for="field in printableFields" :key="field" class="accordion__content">
				<span class="accordion__content--key">{{ $t(`cfr.${field}`) }}</span>
				<span class="accordion__content--value">{{ feature[field] }}</span>
			</p>
		</template>
	</CvAccordionItem>
</template>

<script>
	import VueTypes from 'vue-types';
	import CounterElement from '@/components/CounterElement/CounterElement.vue';
	import { IconTrashCan } from '@/carbon/icons';

	export default {
		name: 'CfrFeatureAccordionItem',
		components: {
			CounterElement,
		},
		props: {
			feature: VueTypes.shape({
				availability: VueTypes.string.isRequired,
				currency: VueTypes.string.isRequired,
				featureCategory: VueTypes.string.isRequired,
				featureCode: VueTypes.string.isRequired,
				featureDescription: VueTypes.string.isRequired,
				listPrice: VueTypes.string.isRequired,
				maximumAllowed: VueTypes.integer.isRequired,
				price: VueTypes.oneOfType([VueTypes.string, VueTypes.number]).isRequired,
				quantity: VueTypes.integer.isRequired,
			}).isRequired,
		},
		data() {
			return {
				printableFields: [
					'featureCategory',
					'listPrice',
					'availability',
					'featureDescription',
					'maximumAllowed',
				],
			};
		},
		computed: {
			total() {
				if (this.feature?.price === '' || Number.isNaN(this.feature?.price)) {
					return '';
				}
				return this.feature.quantity * this.feature.price;
			},
		},
		created() {
			Object.assign(this, { IconTrashCan });
			this.$on('cv:change', child => this.$parent.$emit('cv:change', child));
		},
		methods: {
			onUpdateQuantity(quantity) {
				this.$emit('onUpdateQuantity', quantity);
			},
			onRemove() {
				this.$emit('onRemove', this.feature.featureCode);
			},
		},
	};
</script>

<style lang="scss" scoped>
	::v-deep .bx--accordion__heading:focus::before {
		border: 2px solid $blue-60 !important;
	}

	::v-deep .bx--accordion__title {
		display: inline-flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;

		& > * {
			margin-left: $spacing-03;
			&:first-child {
				margin-left: 0;
			}
		}

		.item-total-price {
			width: 100%;
			text-align: end;
			font-weight: 600;
		}
	}

	::v-deep .bx--accordion__content {
		padding-right: $spacing-11;
		padding-left: $spacing-03;
	}

	.accordion__content {
		font-size: $spacing-04;
		margin-bottom: $spacing-03;
		display: flex;

		&--key {
			color: $gray-70;
			margin-right: $spacing-02;
			flex-basis: 50%;
		}

		&--value {
			color: $gray-90;
			flex-basis: 60%;
		}
	}

	.remove--button {
		margin-right: 0.2rem;
	}
</style>
